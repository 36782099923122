<template>
<div id="modal" class="modal-backdrop" @click.self="close">
    <div class="modal" role="dialog" aria-labelledby="WithdrawModal" aria-describedby="WithdrawModal">
        <!-- <img src="../../assets/close.svg"
                 class="btn-close"
                 @click="close"
                 aria-label="Close modal"
                 alt=""> -->
        <header class="title"> 
            شناسایی دو عاملی 
        </header>
        <section class="modal-body" style="direction: rtl" >

            <template v-if="twoStep === 'EMAIL'" class="describe">
                <p>کد ۶ رقمی که به ایمیل شما ارسال شده است را وارد نمایید.</p>
            </template>

            <template v-else-if="twoStep === 'SMS'" class="describe">
                <p>کد ۶ رقمی که به شماره
                    <span dir="ltr" style="display:inline-block;direction:ltr">{{mobile}}</span>
                    ارسال شده است را وارد نمایید.
                </p>
            </template>

            <template v-else-if="twoStep === 'GOOGLE_AUTHENTICATOR'" class="describe">
                <template v-if="qrCode">
                    <p>
                        برای فعالسازی شناسایی دوعاملی،ابتدا برنامه‌ی Google Authenticator را دانلود نموده و سپس QR کد زیر را توسط آن اسکن نمایید. </p>
                    <img width="180px" class="qr_code_img" :src="'data:image/png;base64,'+qrCode" alt="">
                    <a class="normal-btn" style="cursor: pointer;margin-bottom: 15px;text-decoration: none;" :href="qrLink">بازکردن در اپ</a>
                    <a style="cursor: pointer" title="کپی" @click="Copy(qrLink.match(/secret=(.*?)&/)[1])">{{qrLink.match(/secret=(.*?)&/)[1]}}</a>
                    <p> پس از اسکن بارکد، مورد مربوط به نوتیبای در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.</p>
                </template>
                <p v-else>
                    کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.
                </p>
            </template>
            <div  @paste="paste">
                <CodeInput  :loading="false" class="input" @change="onChange" @complete="submit" :fields="num" ref="code-input" :fieldWidth="45" :fieldHeight="45" />
            </div>
            
            <p class="resend-code" v-if="twoStep !== 'GOOGLE_AUTHENTICATOR'">
                <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
            </p>
        </section>
        <footer class="modal-btn">
            <button v-if="countDown===0" class="active-btn" @click.prevent="send">
                <span> ارسال مجدد</span>
            </button>
            <button v-else :disabled="(twoStep !== 'GOOGLE_AUTHENTICATOR'? countDown === 0 : false) || otp.code.length < 6" type="button" class="active-btn" @click="submit">
                <span>تایید</span>
            </button>

        </footer>
    </div>
</div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {
    Copy,
    reset,
    paste
} from "@/library/reuseableFunction";

export default {
    name: "setTwoStepModal",
    components: {
        CodeInput
    },
    props: ['twoStep', 'qrCode', 'qrLink', 'mobile'],
    data() {
        return {
            amount: '',
            twostepcode: '',
            walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
            smscode: [],
            error: '',
            num: 6,
            otp: {
                code: '',
            },
            timeOut: '',
            countDown: 120,
        }
    },
    computed: {

    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.reset()
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=EMAIL`)
            if (res.message === 'Verification Code Sent') {
                this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        close() {
            this.$emit('close');
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.state.loading = true
            const a = {
                'EMAIL': 'ایمیل',
                'SMS': 'پیامک',
                'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
            }
            if (this.otp.code.toString().length === 6 && (this.twoStep === 'GOOGLE_AUTHENTICATOR' || this.countDown !== 0)) {
                if (this.$route.name !== 'Wallet') {
                    this.state.loading = true
                    const [res, ] = await this.$http.post(`/users/change-otp?twoStepAuthorization=${this.twoStep}&code=${this.otp.code}`)
                    if (res) {
                        this.reset()
                        this.$emit('close')
                        this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + a[this.twoStep] + ' تغییر یافت.')
                    }
                } else {
                    this.$emit('submit', this.otp.code)
                }
            }
            this.state.loading = false
        },
        Copy,
        paste,
        reset
    },
    mounted() {
        // this.$refs['code-input'].$refs['input_0'][0].focus()
        if (this.twoStep !== 'GOOGLE_AUTHENTICATOR') this.countDownTimer()
        document.body.style.overflow = 'hidden'
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
        document.body.style.overflow = 'auto'
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(93, 109, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    border-radius: 3px;
    max-width: 400px;
    width: 90vw;
    min-height: 332px;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: absolute;
    row-gap: 20px;
}

.modal-body {
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-svg {
    position: absolute;
    right: 10px;
    z-index: 111;
}

.title {
    color: #3D49CC;
    font-size: 30px;
    margin: 0 10px;
}

.describe {
    font-size: 12px;
}

.deActive-btn,
.active-btn {

    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    color: white;
}

.deActive-btn {
    background: rgba(61, 73, 204, 0.5);
}

.active-btn {
    background: rgba(61, 73, 204, 1);
}

.qr_code_img {
    margin: 0 auto;
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
}
</style>
