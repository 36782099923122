<template>
<div class="limitExchange" :class="{'dark-child':this.state.dark , 'light-child':this.state.light , 'neon-child':this.state.neon}">
    <form class="buy" @input="calculate">
        <div class="inputs">
            <div class="balance">
                <div class="balance-amount">
                    <p>
                        موجودی :
                    </p>
                    <p class="balance-number" @click="amount = $toLocal(($S2N(cash)/$S2N(unitPrice)),precision)">
                        {{$toLocal(cash,precision)}}

                    </p>
                    <p>
                        {{$coinUnit[tradeTo]}}
                    </p>
                </div>
                <div>
                    <p class="increase" @click="$router.push('/wallet')">
                        افزایش موجودی
                    </p>
                </div>
            </div>
            <input type="text" class="sellPrice" :placeholder="'قیمت'+'('+$coinLabel[tradeTo]+')'" :disabled="limit == 'market'" @input="unitPrice = $toLocal(unitPrice,tradeTo=='TOMAN' ? 0 : 6)" v-model="unitPrice" :id="'unitprice'+id">
            <input type="text" class="sellPrice" :placeholder="'حد توقف'+'('+$coins[tradeTo].persianName+')'" @input="stopPrice = $toLocal(stopPrice,tradeTo=='TOMAN' ? 0 : 6);calculate()" v-model="stopPrice" :id="'stopprice'+id" v-if="limit == 'stopLimit' || limit == 'OCO'">
            <input type="text" class="pricBtcPrice" :placeholder="`مقدار ${(($coinUnit[tradeFrom]))}`" @input="amount = $toLocal(amount,decimal)" v-model="amount" :id="'amount'+id">
            <!-- <input type="range" class="rangeSell" min="0" max="100"  > -->
            <InputRange @change="putcash" />
            <date-picker v-if="limit=='limit'" id="exp" type="datetime" v-model="eDate" color="#efb90a" :format="$dateFormat['en']" :display-format="$dateFormat['faDateTime']" :min="now" clearable class="col-7 col-sm-12 col-md-7 m-0" placeholder="تاریخ انقضا (اختیاری)" />
            <div class="min-max"  v-if="limit == 'limit'">
                <span>
                    پایین ترین پیشنهاد فروش
                </span>
                <span>
                    {{$toLocal(state.shopInfo.minSell,tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom])}} {{$coins[tradeTo].persianName}}
                </span>
            </div>
            <div class="min-max">
                <span>
                    حداقل مقدار معامله
                </span>
                <span>
                    {{minAmountOrder}}
                </span>
            </div>
            <div class="min-max">
                <span>
                    حداکثر مقدار معامله
                </span>
                <span>
                    {{maxAmountOrder}}
                </span>
            </div>
        </div>
        <div class="button">
            <div class="total">
                <p>
                    مجموع
                </p>
                <p>
                    {{totalPrice}} {{$coins[tradeTo].persianName}}
                </p>
            </div>
            <button class="buy-btn" @click.prevent="showmodal" :disabled="!!readonly">
                <!-- :disabled="readonly" -->
                <p v-if="readonly" class="error-p">
                    {{readonly}}
                </p>
                <span v-else> خرید {{$coinLabel[tradeFrom]}}</span>
            </button>
        </div>
        <ShopModal v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="totalPrice" :stopPrice="stopPrice" :amount="amount" type="BUY" :date="eDate" :limit="limit" @accept="show = false" />
    </form>
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    precision,
    Loop
} from "@/library/reuseableFunction";
import ShopModal from '../Modals/ShopModal.vue'
import InputRange from '../Tools/InputRange.vue'
export default {
    name: 'BuyCrypto',
    components: {
        ShopModal,
        InputRange
    },
    props: ['cash', 'wage', 'decimal', 'limit', 'id', 'role'],
    data() {
        return {
            eDate: '',
            percent: 0,
            unitPrice: '',
            stopPrice:'',
            amount: '',
            totalPrice: '',
            danger: false,
            danger1: false,
            danger2: false,
            show: false,
            value: '',
            checked: '',
            calculating: false,
            getAmount: false,
            amountTemp: '',
            count: 0,
            alive: true,
            loop: '',
            coins: [],
            minAmountOrder: 0,
            maxAmountOrder: 0
        }
    },
    watch: {
        // 'state.buyAmount'() {
        //     this.amount = this.$toLocal(this.state.buyAmount, this.decimal)
        //     this.unitPrice = this.$toLocal(this.state.buyPrice, this.precision)
        //     this.calculate()
        // },
        // 'state.buyPrice'() {
        //     this.amount = this.$toLocal(this.state.buyAmount, this.decimal)
        //     this.unitPrice = this.$toLocal(this.state.buyPrice, this.precision)
        //     this.calculate()
        // },
        'state.sellInfo.amount'() {
            if (this.limit == 'market') {
                this.amount = this.$toLocal(this.state.sellInfo.amount, this.decimal)
                this.calculate()
            }
        },
        'sellPrice'() {
            if (this.limit == 'limit') {
                this.unitPrice = this.$toLocal(this.sellPrice.unitPrice, this.$decimal[tradeTo])
                this.amount = this.$toLocal(this.sellPrice.amount, this.$decimal[tradeFrom])
                this.calculate()
            }
        }
    },
    computed: {
        now() {
            return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
        },
        readonly() {
            let priceError = ""
            if (this.limit == 'limit' && !this.$S2N(this.unitPrice)) {
                priceError = 'قیمت واحد اجباریست'
            } else if (this.limit !== 'limit' && !this.$S2N(this.unitPrice)) {
                priceError = 'مقدار درخواستی مجاز نیست'
            } else if (!this.$S2N(this.amount)) {
                priceError = 'مقدار ارز اجباریست'
            } else if (this.$S2N(this.amount) < this.min) {
                priceError = 'مقدار ارز کمتر از حد مجاز است'
            } else if (this.$S2N(this.amount) > this.max) {
                priceError = 'مقدار ارز بیشتر از حد مجاز است'
            } else if (this.$S2N(this.totalPrice) > this.$S2N(this.cash) && !this.state.userInfo.supervisor) {
                priceError = 'موجودی کافی نیست'
            }
            // else if (this.load) {
            //     priceError = 'لطفا صبر کنید...'
            // } 
            else if (this.limit !== 'limit' && this.state.userInfo.supervisor) {
                priceError = 'بازارگردان قادر به انجام سفارش بازار نیست'
            }
            return priceError
        },
        load() {
            return this.calculating ||
                this.getAmount
        },
        min() {
            return this.role.minAmount
        },
        max() {
            return this.role.maxAmount
        },
        range() {
            return 'حداقل ' + this.$toLocal(this.min) + ' ' + this.$coinLabel[this.tradeFrom] +
                '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' ' + this.$coinLabel[this.tradeFrom]
        },
        tradeTo,
        tradeFrom,
        precision
    },
    methods: {
        async putcash(p) {
            if (this.limit == 'limit') {
                let up = this.$S2N(this.unitPrice)
                if (up) {
                    this.percent = p
                    let e = (this.percent * this.cash) / up
                    this.amount = this.$toLocal(e, this.decimal)
                    this.calculate()
                    this.danger = 0
                } else {
                    this.danger = 1
                }
            }
            if (this.limit == 'market') {
                this.percent = p
                let e = this.percent * this.cash
                if (e) {
                    this.getAmount = true
                    const res = await this.$http('/orders/amount-via-price?marketType=' + this.tradeFrom + '_' + this.tradeTo + '&orderType=MARKET_BUY&amount=' + e, {})
                    this.getAmount = false
                    if (res) {
                        // console.log('resssssssss',res);
                        this.amount = this.$toLocal(res[0].baseDTO.wholePrice, this.decimal)
                        this.calculate()
                    }
                }
            }

        },
        setMin() {
            if (this.limit == 'limit') {
                this.unitPrice = this.$toLocal(this.state.shopInfo.minSell, this.precision)
                this.danger = false
                this.calculate()
            }
        },
        localCalculate() {
            const up = this.$S2N(this.unitPrice)
            const a = this.$S2N(this.amount)
            this.totalPrice = this.$toLocal((up * a), this.precision)
            this.percent = this.$S2N(this.totalPrice) / this.cash
        },
        async serverCalculate() {
            this.loop ? this.loop.stop() : ''
            this.calculating = true
            const a = this.amount
            await this.$sleep(500)
            if (a === this.amount) {
                this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceBuy')
                this.loop.start()
            }
        },
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            const [res, ] = await this.$http.get('/orders/market-buy-sell-whole', {
                params: {
                    amount: amount,
                    marketType: this.tradeFrom + '_' + this.tradeTo,
                    orderType: 'MARKET_BUY'
                }
            })
            if (res) {
                this.totalPrice = this.$toLocal(res.baseDTO.wholePrice, this.precision);
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount), this.precision);
                this.percent = this.$S2N(this.totalPrice) / this.cash
            }
            this.calculating = false
        },
        async getAmountFromServer(p) {
            this.percent = p
            let e = this.percent * this.cash
            if (e) {
                this.getAmount = true
                const res = await this.$http('/orders/amount-via-price', {
                    params: {
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY',
                        amount: e
                    }
                })
                this.getAmount = false
                if (res) {
                    this.amount = this.$toLocal(res.baseDTO.wholePrice, this.decimal)
                    this.calculate()
                }
            }
        },
        calculate() {
            if (this.limit == 'limit' && this.unitPrice && this.amount) {
                this.localCalculate()
            } else if (this.limit != 'limit' && this.$S2N(this.amount)) {
                this.serverCalculate()
            }
        },
        showmodal() {
            if (!(this.limit !== 'limit' || this.$S2N(this.unitPrice))) {
                this.danger = true
                this.danger1 = false
                this.danger2 = false
            } else if (!this.$S2N(this.amount)) {
                this.danger1 = true
                this.danger2 = false
                this.danger = false
            } else if (this.percent > 1 && !this.state.userInfo.supervisor) {
                this.danger2 = true
                this.danger1 = false
                this.danger = false
            } else {
                this.show = true
                this.danger = false
                this.danger1 = false
                this.danger2 = false
            }
        },
        async getCoins() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.minAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).minAmount
            this.maxAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).maxAmount
        },
    },
    mounted() {
        this.getCoins()
        if (this.limit == 'market' && this.state.sellInfo.amount) {
            this.amount = this.$toLocal(this.state.sellInfo.amount, this.decimal)
            this.calculate()
        }
    },
    beforeDestroy() {
        (this.loop ? this.loop.stop() : '')
    }
}
</script>

<style scoped>
.limitExchange {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    flex-grow: 1;

}

.sell,
.buy {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.total,
.balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total {
    margin: 10px 0;
}

.total p {
    font-weight: normal;
    font-size: 12px;
}

.sell-btn,
.buy-btn {
    border-radius: 3px;
    height: 44px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
}

.sell-btn {
    background: #D72F70;
    border: 1px solid #D72F70;
}

.buy-btn {
    background: #80CC52;
    border: 1px solid #80CC52;
}

.balance-amount {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    font-size: 12px;
    color: #999999;
    column-gap: 5px;
}

.increase {
    font-weight: normal;
    font-size: 12px;
    color: rgba(61, 73, 204, 0.6);
    border-bottom: 1px solid rgba(61, 73, 204, 0.6);
    cursor: pointer;
}

.balance-number {
    border-bottom: 1px solid #999999;
    cursor: pointer;
}

input {
    color: rgba(61, 73, 204, 1);
}

input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid #EDEFF2;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    box-sizing: border-box;
    margin: 5px 0;
}

input[type="text"]:focus {
    border: 1px solid rgba(61, 73, 204, 1);
}

input::placeholder {
    font-weight: normal;
    font-size: 12px;
    color: rgba(153, 153, 153, 0.6);
}

input[type='range'] {
    cursor: pointer;
    -webkit-appearance: none;
    direction: ltr;
    width: 100%;
    box-sizing: border-box;
    height: 3px;
    /* background: #EDEFF2; */
    border-radius: 1px;
    padding: 0;
}

p {
    margin: 0;
}

.error {
    color: red;
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.min-max {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #999999;
    height: 30px;
}

@media only screen and (max-width:900px) {
    .limitExchange {
        flex-wrap: wrap;
    }
}
</style>
