<template>
<div class="dashboard">
    <div class="title">
        <div class="email-warning">
            <span class="email">
                {{email}}
            </span>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span class="warning" @click="$router.push('/profile')">
                    {{warning}}
                </span>
            </div>
        </div>
        <button class="level" @click="userLevels=!userLevels">
            سطح کاربری : {{$levels[state.userInfo.userAccountLevel]}} <svg viewBox="0 0 10 5" width='15px' fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.00012 5C4.69998 5 4.39988 4.88709 4.17135 4.66122L0.114688 0.659464C-0.0380076 0.508831 -0.0382615 0.264347 0.114121 0.113385C0.266543 -0.0375759 0.513848 -0.0378271 0.666563 0.112825L4.7235 4.11485C4.87609 4.26568 5.12389 4.2657 5.27621 4.11514L9.33344 0.112825C9.48617 -0.0378078 9.73348 -0.0375759 9.88588 0.113385C10.0383 0.264347 10.038 0.508831 9.88531 0.659464L5.82834 4.66149C5.6001 4.88713 5.30008 5 5.00012 5Z" fill="white" />
            </svg>
            <transition name="fade">
                <div class="user-levels" v-if="userLevels">
                <table>
                    <tr class="tr-head">
                        <td>
                            سطح کاریری
                        </td>
                        <td>
                            میزان معامله در ۳ ماه اخیر
                        </td>
                        <td>
                            کارمزد
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index" :class="{'active-level' : $levels[level.userAccountLevel].includes($levels[state.userInfo.userAccountLevel])}">
                        <td>
                            {{$levels[level.userAccountLevel]}}
                        </td>
                        <td class="number-font" v-if="index === 0">
                            کمتر از {{tradeAmount($accountLevel[index].tradeAmount)}} میلیون
                        </td>
                        <td class="number-font" v-else-if="index === ($accountLevel.length-2)">بیشتر از
                            {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                            میلیون
                        </td>
                        <td v-else>
                            از
                            {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                            تا
                            {{tradeAmount($accountLevel[index].tradeAmount)}}
                            میلیون
                        </td>
                        <td>
                            % {{level.wagePercent}}
                        </td>
                    </tr>
                </table>
            </div>
            </transition>
        </button>
    </div>
    <div class="orders-container">
        <OpenOrders />
        <!-- <Appexchart /> -->
        <div class="apex_div">
            <div class="apex_toggle">
                <div class="apex_market" :class="{'active_toggle' : walletType=='P2P'}" @click="walletType='P2P';getWallets()">
                    دارایی بازار
                </div>
                <div class="apex_exchange" :class="{'active_toggle' : walletType=='EXCHANGE'}" @click="walletType='EXCHANGE';getWallets()">
                    دارایی صرافی
                </div>
            </div>
            <apexchart type="donut" width="250" height="280" :options="chartOptions" :series="series" :key="series.length + '' + chartOptions.labels.length + change " />
        </div>
    </div>
    <div class="log-statistics">
        <div class="statistics">
            <div class="friends">
                <p class="statistics-title">
                    تعداد دوستان من
                </p>
                <p class="statistics-number">
                    {{data.countInvitees}}
                    <span class="statistics-unit">
                        نفر
                    </span>
                </p>
            </div>
            <div class="income">
                <p class="statistics-title">
                    مجموع سود من
                </p>
                <p class="statistics-number">
                    {{$toLocal(data.profit)}}
                    <span class="statistics-unit">
                        تومان
                    </span>
                </p>
            </div>
            <button class="invite-btn" @click="$router.push('/invite-friends')">
                دعوت دوستان
            </button>
        </div>
        <div class="logins-container">
            <p class="title-login">
                ورود ها
            </p>
            <Logins />
        </div>

    </div>
</div>
</template>

<script>
import OpenOrders from './OpenOrders.vue'
import Logins from '../History/Logins.vue'
// import InlineSvg from 'vue-inline-svg';

export default {
    name: 'Dashboard',
    components: {
        OpenOrders,
        Logins,
        // InlineSvg
    },
    data() {
        return {
            userLevels: false,
            email: this.state.userInfo.email,
            warning: "تکمیل اطلاعات کاربری",
            series: [0],
            change: 0,
            walletType: 'P2P',
            wallets: [],
            data: {
                countInvitees: 0,
                profit: '',
                referralCode: '',
            },
            chartOptions: {
                chart: {
                    width: '50px',
                    fontFamily: 'shabnam',
                },
                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'shabnam'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: 'white',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'var(--tooDark)',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 400,
                                    color: '#3D49CC',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
            },
        }
    },
    computed: {
        tradeAmount() {
            return e => e ? e.toString().slice(0, -6) : 0
        }
    },
    methods: {
        async getWallets() {
            this.wallets = []
            this.series = []
            const res = await this.$axios.get('/wallets/customer-addresses?walletType=' + this.walletType)
            let entireRes = Object.entries(res)
            const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN']
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            this.wallets = entireRes
            this.chart()
        },

        chart() {
            console.log('wallet', this.wallets);
            let index = 0
            this.wallets.forEach((e) => {
                    this.chartOptions.labels[index] = {
                        'name': this.$coinLabel[e[0]],
                        'value': e[1].credit || 0
                    }
                    this.series[index] = e[1].totalBalance || e[1].wholePriceOfCredit || 0
                    console.log('ssseeeerrrrr', this.series);
                    index++
                })
                ++this.change
        },

    },
    async mounted() {
        this.state.loading = true
        this.$getFees()
        await this.getWallets()
        const res = await this.$axios.get('/users/invites')
        this.data = res.baseDTO
    },

}
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    // max-width: 995px;
    width: 100%;
}

.title {
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 84px;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}

.level {
    background: #3D49CC;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    color: #FFFFFF;
    width: 150px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .user-levels {
        position: absolute;
        top: 40px;
        background: white;
        box-shadow: 0px 1px 10px #3d49cc;
        z-index: 2;
        padding: 0 5px;
        left: 0;
        width: 280px;
        height: 180px;

        table {
            width: 100%;
            color: black;
            border-collapse: collapse;

            .tr-head {
                border-bottom: 2px solid #3d49cc;
                height: 30px;

                :first-child {
                    text-align: right;
                }

                :last-child {
                    text-align: left;
                }
            }

            .tr-body {
                opacity: 0.7;
                height: 35px;

                :first-child {
                    text-align: right;
                }

                :last-child {
                    text-align: left;
                }
            }

            .active-level {
                background: rgba(255, 166, 0, 0.5);
            }
        }
    }
}

.email-warning {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.warning-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.warning {
    font-size: 12px;
    color: #EB5757;
    border-bottom: 1px solid #EB5757;
    width: max-content;
    cursor: pointer;
}

.statistics {
    background: #FFFFFF;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 20px;
    // width: 65vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 320px;
    min-width: 250px;
    flex-grow: 1;

    p {
        margin: 0;
    }
}

.invite-btn {
    background: #80CC52;
    border-radius: 3px;
    height: 44px;
    width: 100%;
    border: none;
    color: #FFFFFF;
}

.logins-container {
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    column-gap: 15px;
    box-sizing: border-box;
    padding: 20px;
    // width: 148vw;
    max-height: 310px;
    // overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    // max-width: 670px;
}

.logins-container::-webkit-scrollbar {
    display: none;
}

.title-login {
    font-size: 20px;
    margin: 0;
}

.log-statistics {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    // flex-wrap: wrap;
}

.balance-img {
    max-width: 310px;
    height: 310px;
    width: 90%;
}

.orders-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 15px;
}

.statistics-title {
    font-size: 20px;
}

.statistics-number {
    font-size: 32px;
    color: #80CC52;
}

.statistics-unit {
    font-size: 18px;
}

.apex_div {
    display: flex;
    flex-direction: column;
    max-width: 310px;
    width: 100%;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;

    .apex_toggle {
        display: flex;
        justify-content: space-between;
        width: 250px;
        font-size: 14px;

        .apex_market,
        .apex_exchange {
            color: #3D49CC;
            opacity: 0.5;
            cursor: pointer;
        }

        .active_toggle {
            border-bottom: 2px solid #3D49CC;
            opacity: 1;
            cursor: pointer;
        }
    }
}
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease-out;
  max-height: 230px;
}

.fade-enter, .fade-leave-to {
   max-height: 0px;
}
@media only screen and (max-width:1000px) {
    .log-statistics {
        flex-wrap: wrap;
    }
}
</style>
