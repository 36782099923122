<template>
<div class="main-container">
<div class="table-container">
    <div class="title">
        <p class="tooman" :class="{'active' : tooman}" @click="setMarket">
            تومان
        </p>
        <p class="tether" :class="{'active' : tether}" @click="setMarket">
            تتر
        </p>
    </div>
    <table>
        <tr class="tr-head">
            <td v-for="tableTitle , index in tableTitle" :key="index" :class="tableTitle.class">
                {{tableTitle.title}}
            </td>
        </tr>
        <tr class="tr-body" v-for="(coin , index) in coins" :key="index" @click="goto($coin2Snake[coin.relatedCoin])">
            <td>
                <div class="first-td">
                    <img class="coin-img" :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" alt="">
                    <span>{{$coinLabel[coin.relatedCoin]}}</span>
                    <span class="ENname">({{coin.relatedCoin}})</span>
                </div>
            </td>
            <td>
                <span>{{$toLocal(coin.lastPrice,$decimal[tooman ? 'TOMAN' : 'TETHER'])}} </span>
                <!-- <span v-if="tether">{{crypto.priceTether.toLocaleString()}} </span> -->
            </td>
            <td class="change" :class="{'positive-change':coin.last24HourChange > 0 , 'negetive-change':coin.last24HourChange < 0}">
                <span v-if="coin.last24HourChange > 0">+</span> {{$toLocal(Math.abs(coin.last24HourChange),1)}} %
            </td>
            <td class="maxPrice">
                <span>{{$toLocal(coin.lastPrice,$decimal[tooman ? 'TOMAN' : 'TETHER'])}}</span>
                <!-- <span v-if="tether">{{crypto.MaxPriceTeter.toLocaleString()}}</span> -->
            </td>
            <td class="minPrice">
                <span>{{$toLocal(coin.minPrice,$decimal[tooman ? 'TOMAN' : 'TETHER'])}}</span>
                <!-- <span v-if="tether">{{crypto.MinPriceTeter.toLocaleString()}}</span> -->
            </td>
            <td class="exchange-td">
                {{$toLocal(coin.last24HourVolume,$decimal[tooman ? 'TOMAN' : 'TETHER'])}}
            </td>
            <td class="td-chart">
                <TrendChart :trend='coin.lastWeekData' />
                <!-- <img class="chart.img" src="@/assets/chart.png" alt=""> -->
            </td>
        </tr>
    </table>
</div>
</div>
</template>

<script>
import TrendChart from './TrendChart.vue'
import crypto from "../../library/crypto.js"
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'HomeTable',
    components: {
        TrendChart
    },
    data() {
        return {
            cryptoList: crypto,
            tooman: true,
            tether: false,
            tableTitle: [{
                    title: "بازار",
                    class: ""
                },
                {
                    title: " قیمت",
                    class: ""
                },
                {
                    title: "تغییرات",
                    class: ""
                },
                {
                    title: "بیشترین قیمت",
                    class: "maxPrice"
                },
                {
                    title: "کمترین قیمت",
                    class: "minPrice"
                },
                {
                    title: "حجم معاملات",
                    class: "exchange-td"
                },
                {
                    title: "نمودار",
                    class: "td-chart"
                }

            ],
            coins: [],
            currency: "toman",
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +
                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +
                '&market_type=TETHER_TOMAN',

            TetherCoin: 'market_type=BITCOIN_TETHER' +
                '&market_type=BITCOIN_CASH_TETHER' +
                '&market_type=ETHEREUM_TETHER' +
                '&market_type=LITE_COIN_TETHER' +
                '&market_type=BINANCE_COIN_BSC_TETHER' +
                '&market_type=MATIC_TETHER' +
                '&market_type=STELLAR_TETHER' +
                '&market_type=TRON_TETHER' +
                '&market_type=RIPPLE_TETHER' +
                '&market_type=DOGE_COIN_TETHER' +
                '&market_type=CELO_TETHER',
            loop: undefined,
        }
    },
    watch:{
        'coins'() {
            if(!this.coins) {
                this.state.loading = true
            }
            else this.state.loading = false
        }
    },
    methods: {
        setMarket() {
            if (this.tooman == false) {
                this.tooman = true
                this.tether = false
            } else {
                this.tether = true
                this.tooman = false
            }
            this.setData()
        },
        async setData() {
            // this.state.loading = true
            const res = await this.$axios.get('/trades/market-info?' + (this.tooman ? this.TomanCoin : this.TetherCoin), {
                params: {
                    weeklyDataIncluded: true,
                }
            })
            this.coins = res.content
        },
        goto(e) {
            this.$router.push('/advanced-trade/' + (this.tooman ? 'TOMAN' : 'TETHER') + `/${e}`)
        },
        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.setData, 5000, 'setData' + 'Toman')
                this.loop.start()
            } else {
                this.setData()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }
    },
    mounted() {
        this.beforeLoop()
    }
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.main-container {
    width: 100%;
    height: 100%;
    padding: 50px 5px;
    background: #F2F4F5;
}
.table-container {
    background: white;
    margin:0 auto;
    margin-top: 30px;
    padding: 60px 20px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

    .title {
        display: flex;
        column-gap: 20px;
        justify-content: center;

        .tooman,
        .tether {
            font-weight: normal;
            font-size: 18px;
            color: #999999;
            cursor: pointer;
            width: 70px;
            text-align: center;
        }

        .active {
            color: #404040;
            font-weight: bold;
            border-bottom: 3px solid #3D49CC;
            border-radius: 2px;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        // min-width: 800px;
        // table-layout: fixed;
        max-width: 1250px;

        .tr-head td {
            font-weight: normal;
            font-size: 14px;
            color: #999999;
        }

        tr {
            height: 70px;
            border-bottom: 1px solid rgba(61, 73, 204, 0.1);
        }

        .tr-body {
            font-size: clamp(12px, 1vw, 16px);
            cursor: pointer;

            &:hover {
                background: rgba(61, 73, 204, 0.05);
            }

            td:first-of-type {
                width: 250px;
            }

            .first-td {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 10px;

                .coin-img {
                    width: 40px;
                    height: 40px;
                }
            }

            .chart.img {
                width: 120px;
            }

            td.change {
                direction: ltr;
            }

            .positive-change {
                color: #80CC52;
            }

            .negetive-change {
                color: rgb(247, 131, 131);

            }
        }
    }
}

.table-container::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width:992px) {

    .coin-img,
    .ENname,
    .maxPrice,
    .minPrice,
    .td-chart,
    .exchange-td {
        display: none;
    }

    table {
        table-layout: fixed;
    }
}
</style>
