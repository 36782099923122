<template>
<div>
    <div class="about">
        <p class="title">
           درباره صرافی
           <span class="bamboo"> نوتیبای </span>
        </p>
        <div class="about-container">        
            <img class="bamboo-img" src="@/assets/bamboo.png" alt="">
            <p class="describe">
              صرافی نوتیبای از اولین صرافی‌های ارز دیجیتال ایرانی است. این صرافی با ارائه خدمات متنوع و پشتیبانی 24 ساعته، پلتفرمی امن برای مبادلات ارزهای دیجیتال است. چهار سال سابقه در حوزه کریپتوکارنسی، نوتیبای را به یکی از پیشگامان و اثر گذاران بر بازار داخلی ارزهای دیجیتال در ایران تبدیل کرده است.

نوتیبای، یک پلتفرم آنلاین برای خرید و فروش ارزهای دیجیتال است. این صرافی به‌عنوان یک واسطه میان خریداران و فروشندگان ارزهای دیجیتال عمل می‌کند. کاربران در نوتیبای با ارائه نرخ خرید و فروش مورد نظر خود با سایر کاربران اقدام به معامله می‌کنند 
            </p>
            <div class="options">
              <div class="option">
                  <img class="option-img" src="@/assets/fee.png" alt="">
                  <p>
                      کارمزد  پایین معاملات
                  </p>
              </div>
              <div class="option">
                  <img class="option-img" src="@/assets/speed.png" alt="">
                  <p>
                      واریز و انتقال سریع وجه
                  </p>
              </div>
              <div class="option">
                  <img class="option-img" src="@/assets/safe.png" alt="">
                  <p>
                      ذخیره امن دارایی ها
                  </p>
              </div>
              <div class="option">
                  <img class="option-img" src="@/assets/support.png" alt="">
                  <p>
                      پشتیبانی 24 ساعته
                  </p>
              </div>
          </div>
          
        </div>
    </div>
          <div class="statistics-container">
            <Statistics/>
          </div>
      <div class="about">
        <p class="describe">
        با بررسی بخش بازارها در وب‌سایت نوتیبای، می‌توانید از قیمت‌ لحظه‌ای ارزهای دیجیتال معتبر و محبوب جهانی که در نوتیبای عرضه شده‌اند باخبر شوید. قیمت‌ها در نوتیبای به صورت لحظه‌ای بر پایه بازار تتر (دلاری) و تومان قابل مشاهده است.
        </p>
        <p class="describe">
          نوتیبای در حال حاضر نزدیک به 40 کوین معتبر دنیا از جمله بیت کوین (BTC) ، اتریوم (ETH) ، تتر(USDT) ، دوج کوین (DOGE) ، شیبا (SHIB) و بسیاری دیگر از توکن‌های محبوب را در پلتفرم معاملاتی خود دارد. امکان معامله این توکن‌ها بر دو پایه بازار ریالی، تتری  با سایر کاربران وجود دارد.
        </p>
      </div>
</div>
</template>

<script>
import Statistics from '../HomePage/Statistics.vue'

export default {
  name: 'HomePage',
  components :{
    Statistics
    
  },
  data() {
      return {
          
      }
  },}
</script>

<style scoped>
    .about {
        width: 80%;
        margin:0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
    }
    .title {
        font-weight: bold;
        font-size: 30px;
    }
    
    .about-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: center;
        align-items: center;
        row-gap: 50px;
    }
    .bamboo {
      color: #80CC52;
    }
    .bamboo-img {
      width: 150px;
      height: 150px;
    }
    .bamboo { 
        color: #80CC52;
    }
    .options {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 40px;
        row-gap: 20px;
    }
    .option-img {
        width: 150px;
        height: 150px;
    }
    .option {
        font-weight: normal;
        font-size: 18px;
    }
    .statistics-container {
      width: 100%;
      background: #3D49CC;
      margin: 40px 0;
    }
    .describe {
      margin: 0;
      font-weight: normal;
      font-size: 18px;
    }
    @media only screen and (max-width:1000px) {
        .options {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
</style>
