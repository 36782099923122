<template>
<div class="trendChart-parent">
    <svg style="width:0; height:0; position:absolute;" aria-hidden="true" focusable="false">
        <defs>
            <linearGradient id="btcFill" x1="0" x2="0" y1="0" y2="100%">
                <stop offset="0%" stop-color="rgba(87, 98, 218, 0.2)"></stop>
                <stop offset="100%" stop-color="rgba(87, 98, 218, 0)"></stop>
            </linearGradient>
        </defs>
    </svg>
    <TrendChart :key="test" :datasets="data" :grid="{
        verticalLines: true,
        horizontalLines: true,
      }">
    </TrendChart>
</div>
</template>

<script>
export default {
    name: "TradingChart",
    components: {},
    props: ["trend"],
    data() {
        return {
            data: [],
            test: 0,
            chartData: []
        };
    },
    methods: {},
    mounted() {
        if (this.trend) {
            for (var i = 0; i < 20; i++) {
                this.chartData[i] = this.trend[i] / 10
            }
            this.data = [{
                    data: this.chartData,
                    smooth: true,
                    fill: true,
                }, ]
                ++this.test
        } else {
            this.chartData = [0, 0, 0, 0, 0]
        }
    },
};
</script>

<style scoped>
.trendChart-parent {
    display: flex;
    justify-content: center;
}
</style>
<style lang="scss">
.vtc {
    height: 50px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    // path {
    //     fill: url(#btcFill);
    // }
}

.vtc * {
    stroke-width: 2px;
   
    fill: linear-gradient(180deg, rgba(87, 98, 218, 0.2) -2.33%, rgba(87, 98, 218, 0) 100%) !important;
}

.vertical {
    display: none;
}

.curve {
    height: 100%;
    display: flex;
    flex-grow: 1;

}

.curve path:first-of-type {
    // display: none;
}

.fill {
    fill: url(#btcFill) !important;
}
.stroke {
     stroke: rgba(87, 98, 218, 0.5);
}

// .vtc {
//     height: 250px;
//     font-size: 12px;
//     @media (min-width: 699px) {
//       height: 350px;
//     }
//     fill: url(#btcFill);
//   }
//   .grid,
//   .labels {
//     line {
//       stroke: rgba(#f69119, 0.5);
//     }
//   }
//   .x-labels {
//     .label {
//       text {
//         display: none;
//       }
//       line {
//         opacity: 0.3;
//       }
//       &:nth-child(6n + 1),
//       &:first-child {
//         text {
//           display: block;
//         }
//         line {
//           opacity: 1;
//         }
//       }
//     }
//   }
//   .curve-btc {
//     .stroke {
//       stroke: #f69119;
//       stroke-width: 2;
//     }
//     .fill {
//       // fill: url(#btcFill);
//       fill-opacity: 0.5;
//     }
//   }
</style>
