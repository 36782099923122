<template>
<div class="app">
    <LoadingPage v-if="state.loading === true"/>
    <ExitModal v-if="state.exit" @closeModal="state.exit = false"/>
    <router-view></router-view>
</div>
</template>

<script>
import LoadingPage from './components/Modals/LoadingPage.vue'
import ExitModal from './components/Public/ExitModal.vue'
export default {
    name: "App",
    components: {
        LoadingPage,
        ExitModal
    },
    data() {
        return {
            // exit : false
            country : ''
        }
    },

}
</script>

<style scoped>
    .app {
        min-height: 100vh;
    }
</style>
