<template>
    <div class="why">
        <p class="title">چرا صرافی <span class="bamboo"> نوتیبای </span></p>
        <div class="options">
            <div class="option" v-for="option , index in option" :key="index">
                <img class="option-img" :src=option.img alt="">
                <p>
                    {{option.title}}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Why',
  components :{
    
  },
  data() {
      return {
          option:[
              {
                  img:require('../../assets/fee.png'),
                  title:"کارمزد  پایین معاملات"
              },
              {
                  img:require('../../assets/speed.png'),
                  title:"واریز و انتقال سریع وجه"
              },
              {
                  img:require('../../assets/safe.png'),
                  title:"ذخیره امن دارایی ها"
              },
              {
                  img:require('../../assets/support.png'),
                  title:"پشتیبانی 24 ساعته"
              }
          ]
      }
  },}
</script>

<style lang="scss" scoped>
    .why {
        width: 100%;
        max-width: 1250px;
        padding: 30px 10px;
        box-sizing: border-box;
            .title {
                font-weight: normal;
                font-size: 32px;
            }
            .bamboo { 
                color: #80CC52;
            }
            .options {
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                -moz-column-gap: 40px;
                column-gap: 60px;
                row-gap: 20px;
                max-width: 1440px;
                flex-grow: 1;
                flex-wrap: wrap;
                    .option-img {
                        width: 150px;
                        height: 150px;
                    }
                    .option {
                        font-weight: normal;
                        font-size: clamp(16px , 1.5vw ,18px);
                    }
            }
    }
    
    
    
    
</style>
