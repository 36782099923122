<template>
<div class="navbar">
    <button class="ham" @click.stop="toggleHamburger">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
    </button>
    <div class="nav-container">
        <div class="title-container">
            <router-link to="/">
                <div class="title">
                    <img class="bamboo-img" src="@/assets/bamboo.png" alt="">
                    <span class="bamboo-title"> صرافی </span> <span class="bamboo-name"> نوتیبای </span>
                </div>
            </router-link>
            <div class="links">
                <a href="/advanced-trade" @click.prevent="$router.push('/advanced-trade')">
                    <span :class="{'active' : $route.path.includes('advanced')}">
                        معامله حرفه ای
                    </span>
                </a>
                <a href="/basic-trade" @click.prevent="$router.push('/basic-trade')">
                    <span :class="{'active' : $route.path.includes('basic')}">
                        معامله آسان
                    </span>
                </a>
                <a href="/online-prices" @click.prevent="$router.push('/online-prices')">
                    <span :class="{'active' : $route.path.includes('online')}">
                        صرافی آنلاین
                    </span>
                </a>
            </div>
        </div>
        <div class="nav-account">
            <div class="profile-options">
                <!-- <span class="guid">
                    دارایی ها
                    <inline-svg :src="require('@/assets/guid-v.svg')" />
                </span> -->
                <a href="/basic-trade" @click.prevent="$router.push('/dashboard')" v-if="$route.path.includes('trade')">
                    <span class="guid">
                        <inline-svg :src="require('@/assets/dashboard.svg')" />
                        داشبورد
                    </span>
                </a>
            </div>
            <div class="trade-options" v-if="$route.path.includes('advanced-trade') || $route.path.includes('basic-trade')">
                <inline-svg class="hover" :src="require('@/assets/Line.svg')" />
                <inline-svg :src="require('@/assets/mobile.svg')" />
                <inline-svg :src="require('@/assets/watch.svg')" @click="showNotices" />
                <inline-svg :src="require('@/assets/bell.svg')" @click="notification" />
                <inline-svg :src="require('@/assets/them.svg')" @click="changeTheme" />
            </div>
        </div>
    </div>
    <!-- <MobileMenu/> -->
    <div class="overlay"></div>
    <nav class="sideNav" v-click-outside="onClickOutside">
        <button class="menuX" @click.stop="toggleHamburger">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <div class="mobile-nav-items">
            <div class="mobile-btn-contaner" v-if="$route.path.includes('trade')">
                <!-- v-if="$route.path=='/advanced-trade' || $route.path=='/basic-trade'" -->
                <button class="mobile-menu-btn">
                    <inline-svg :src="require('@/assets/mobile.svg')" />
                </button>
                <button class="mobile-menu-btn" @click="showNotices" @click.stop="toggleHamburger">
                    <inline-svg :src="require('@/assets/watch.svg')" />
                </button>
                <button class="mobile-menu-btn" @click="notification" @click.stop="toggleHamburger">
                    <inline-svg :src="require('@/assets/bell.svg')" />
                </button>
                <button class="mobile-menu-btn" @click="changeTheme" @click.stop="toggleHamburger">
                    <inline-svg :src="require('@/assets/them.svg')" />
                </button>
            </div>
            <ul>
                <a href="/dashboard" @click.prevent="$router.push('/dashboard')">
                    <span :class="{'active' : $route.path=='/dashboard'}" @click.stop="toggleHamburger">
                        داشبورد
                    </span>
                </a>
                <a href="/advanced-trade" @click.prevent="$router.push('/advanced-trade')">
                    <span :class="{'active' : $route.path=='/advanced-trade'}" @click.stop="toggleHamburger">
                        معامله حرفه ای
                    </span>
                </a>
                <a href="/basic-trade" @click.prevent="$router.push('/basic-trade')">
                    <span :class="{'active' : $route.path=='/basic-trade'}" @click.stop="toggleHamburger">
                        معامله آسان
                    </span>
                </a>
                <a href="/online-prices" @click.prevent="$router.push('/online-prices')">
                    <span :class="{'active' : $route.path=='/online-prices'}" @click.stop="toggleHamburger">
                        صرافی آنلاین
                    </span>
                </a>
            </ul>
        </div>
    </nav>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
// import MobileMenu from './MobileMenu.vue';
import Vue2ClickOutside from 'vue2-click-outside'
export default {
    name: 'Navbar',
    components: {
        InlineSvg,
        // MobileMenu

    },
    data() {
        return {
            notices: true,
            notif: true,
            backColor: "#FFFFFF",
            backcolor2: "#000000",
            //   currenttheme:JSON.parse(JSON.stringify(this.currentThme))
        }
    },
    //   props : {
    //       currentThme:""
    //   },
    directives: {
        clickOutside: Vue2ClickOutside.directive
    },
    methods: {

        showNotices() {
            this.$emit('onNavNotice', this.notices)
        },
        notification() {
            this.$emit('onNavNotif', this.notif)
        },
        theme() {
            if (this.currenttheme === 'black') {
                this.$emit('onNavThem', this.backColor)
            } else if (this.currenttheme === 'white') {
                this.$emit('onNavThem', this.backcolor2)
            } else {
                this.$emit('onNavThem', this.backColor)
            }

            //   console.log(this.backColor)
        },
        toggleHamburger() {
            const sideNav = document.querySelector(".sideNav")
            const overlay = document.querySelector(".overlay")
            overlay.classList.toggle("showOverlay")
            sideNav.classList.toggle("showNav")
        },
        onClickOutside() {
            const sideNav = document.querySelector(".sideNav")
            const overlay = document.querySelector(".overlay")
            overlay.classList.remove("showOverlay")
            sideNav.classList.remove("showNav")
        },
        changeTheme() {
            if (this.state.light) {
                this.state.light = false
                this.state.dark = true
                this.state.neon = false
                this.state.trSell = 'rgba(255, 0, 99, 0.1)'
                this.state.trBuy = 'rgba(97, 255, 0, 0.1)'
                this.state.tradingTheme = "dark"

            } else if (this.state.dark) {
                this.state.light = false
                this.state.dark = false
                this.state.neon = true
                this.state.trSell = 'rgba(255, 0, 99, 0.1)'
                this.state.trBuy = 'rgba(97, 255, 0, 0.1)'
                this.state.tradingTheme = "dark"
            } else {
                this.state.light = true
                this.state.dark = false
                this.state.neon = false
                this.state.tradingTheme = "light"
            }
            console.log(this.state.tradingTheme)

        }
        //       transform(svg) {
        //         let point = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        //         point.setAttributeNS(null, 'cx', '20');
        //         point.setAttributeNS(null, 'cy', '20');
        //         point.setAttributeNS(null, 'r', '10');
        //         point.setAttributeNS(null, 'fill', 'red');
        //         svg.appendChild(point);
        //         return svg;
        // }
    }

}
</script>

<style lang="scss" scoped>
.navbar {
    /* width: 100%; */
    height: 50px;
    /* border: 1px solid #FFFFFF; */
    box-sizing: border-box;
    background: #3D49CC;
    border-bottom: 1px solid #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 10;
    left: 0;
    right: 0;
}

.nav-container {
    // width: 100%;
    // margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}

.bamboo-img {
    width: 50px;
    height: 50px;
}

.bamboo-name,
.bamboo-title {
    font-weight: bold;
    font-size: 14px;
}

.bamboo-name {
    color: #80CC52;
}

.bamboo-title {
    color: #ffff;
}

.login-sub {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
}

.login-p {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

.sub-btn {
    background: #FFFFFF;
    border-radius: 3px;
    border: 0;
    height: 34px;
    width: 90px;
    color: #3D49CC;
}

.links {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    position: relative;
}

.links span {
    font-weight: normal;
    font-size: 14px;
    font-size: clamp(12px, 1.5vw, 14px);
    color: #FFFFFF;
    opacity: 0.5;
    cursor: pointer;
    height: 50px;
    position: relative;
}

.links span:hover {
    opacity: 1;
}

.links span:hover>.guid-v {
    transform: rotate(180deg);
}

.links>span {
    display: flex;
    align-items: center;
}

.guid {
    column-gap: 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;

    svg {
        fill: white !important;
        width: 15px;
        height: 15px;
    }
}

.guid:hover+.guid-options {
    display: block;
}

.guid-options {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    box-sizing: border-box;
    width: 132px;
    height: 200px;
    padding: 10px;
    font-weight: normal;
    font-size: 14px;
    top: 50px;
    left: -30px;
    display: none;
}

.guid-options:hover {
    display: block;
}

.guid-options>p {
    cursor: pointer;
}

a:-webkit-any-link {
    color: black;
}

.title-container {
    display: flex;
    column-gap: 60px;
}

.profile-options {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
}

.guid {
    color: rgba(255, 255, 255, 0.6);
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
}

.trade-options {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
}

.trade-options svg {
    cursor: pointer;
}

.nav-account {
    display: flex;
    column-gap: 30px;
        svg{
            fill: rgba(255, 255, 255, 0.6) !important;
            width: 15px;
        }
}

.links .active {
    opacity: 1 !important;
}

.hr-nav-responsive {
    height: 1px;
    width: 150px;
}

.ham {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem;
    background: none;

    border: none;
    cursor: pointer;
    color: white;
    display: none;

}

.menuX {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.sideNav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    /* background: white; */
    background: #3D49CC;
    width: 160px;
    height: 100vh;
    /* overflow: hidden; */
    overflow: scroll;
    color: white;
    /* hide the menu above the screen by default */
    transform: translateX(100%);
    transition: transform 0.4s ease;

}

.showNav {
    transform: translateX(0);
}

.sideNav ul {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 30px 0;
    padding-right: 0;
    row-gap: 50px;
}

.mobile-nav-items {
    padding-top: 4rem;
}

.sideNav span {
    display: block;
    color: white;
    /* padding-bottom: 10px; */
    /* margin-top: 1rem; */
    /* border-bottom: 2px solid black; */
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 14px;
}

.sideNav ul li a:hover,
.sideNav ul li a:focus {
    border-bottom: 2px solid white;
}

.overlay {
    position: fixed;
    display: none;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
}

.showOverlay {
    display: block;
}

.mobile-menu-btn {
    background: transparent;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    width: 30%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-btn-contaner {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width:1000px) {

    .links,
    .login-sub {
        display: none;
    }
}

@media only screen and (max-width:1000px) {
    .nav-account {
        display: none;
    }

    .ham-icon,
    .ham {
        display: inline-block;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
    }

    .nav-container {
        width: auto;
        margin: 0 10px;
    }
}
</style>
