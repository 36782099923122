<template>
<div class="crypto-container">
    <BuySellModals :buyPrice="buyPrice" :sellPrice="sellPrice" :destCoinSymbol="destCoinSymbol" v-if="(state.BuyModal || state.SellModal)" @openSuccess='openSuccessModal' />
    <SuccessSell v-if="success" :data="successData" @close="success=false" />
    <div class="crypto">
        <div class="statistics-container">
            <div class="statistics">
                <div class="statistics-list">
                    <div class="title">
                        <img src="" alt="">
                        <img class="crypto-img" :src="'/coins/'+ infoCrypto + '.png'" :alt="infoCrypto">
                        <span class="name">
                            {{$coins[infoCrypto].persianName}}
                        </span>
                    </div>
                    <!-- <div class="title-number">
                        <span :class="{'positive-change':info[0].change>0 , 'negative-change':info[0].change<0}">
                            {{cryptoPrice.toLocaleString()}}
                        </span>
                    </div> -->
                </div>
                <div class="statistics-list">
                    <span class="statistics-list-title">
                        درصد تغییرات
                    </span>
                    <span class="statistics-list-number" :class="{'positive-change':percentChange>0 , 'negative-change':percentChange<0}">
                        {{$toLocal(Math.abs(percentChange),2)}}<span v-if="percentChange">%</span>
                        <span v-if="percentChange>0">+</span>
                        <span v-if="percentChange<0">-</span>
                    </span>
                </div>
                <div class="statistics-list">
                    <span class="statistics-list-title">
                        قیمت خرید
                    </span>
                    <span class="statistics-list-number" :class="{'positive-change':percentChange>0 , 'negative-change':percentChange<0}">
                        {{$toLocal(buyPrice , (infoUnit=='TOMAN' ? 0 : 2))}}
                    </span>
                </div>
                <div class="statistics-list">
                    <span class="statistics-list-title">
                        قیت فروش
                    </span>
                    <span class="statistics-list-number" :class="{'positive-change':percentChange>0 , 'negative-change':percentChange<0}">
                        {{$toLocal(sellPrice , (infoUnit=='TOMAN' ? 0 : 2))}}
                    </span>
                </div>
                <div class="btns-container">
                    <button class="buy-btn" @click="Buybtn">
                        خرید {{$coins[infoCrypto].persianName}}
                    </button>
                    <button class="sell-btn" @click="Sellbtn">
                        فروش {{$coins[infoCrypto].persianName}}
                    </button>
                </div>
            </div>
            <div class="traiding-container">
                <vue-trading-view :key="$route.params.infoCrypto" style="height: 400px;width:100%" :options="{
                                symbol: infoUnit !== 'TETHER' ? 'BINANCE:' + $coins[infoCrypto].code + 'USDT' : 'BINANCEUS:USDTUSD',
                                height:'600',
                                autosize:true,  
                                theme:'light',  
                                        timezone: 'Asia/Tehran',
                                    style: '2',
                                    hide_top_toolbar:true,
                                    hide_legend:true,
                                    hide_bottom_toolbar:true,
                                    hide_legend:true,
                                    locale: 'fa_IR',
                                    enable_publishing: false,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: true,
                                    allow_symbol_change: true,
                                    save_image : false,
                                    enabled_features: [ 'header_fullscreen_button' ],
                                    disabled_features: [ 'border_around_the_chart' , 'remove_library_container_border' , ],
                                        loading_screen:{ backgroundColor:'#FFFFFF', foregroundColor: '#FFFFFF' , }, 
                                        overrides: { background: '#FFFFFF' } }" />
            </div>
        </div>
        <hr>
        <div class="describe-container">
            <DescribeCrypto />
            <div class="line">
                <span class="line-title">
                    افزودن نظر
                </span>
            </div>
            <AddComment :reply='false' />
            <div class="line">
                <span class="line-title">
                    نظرات کاربران
                </span>
            </div>
            <CommentsList />
        </div>
    </div>
</div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
import AddComment from './AddComment.vue'
import DescribeCrypto from './DescribeCrypto.vue'
import CommentsList from './CommentsList.vue'
import BuySellModals from '../Modals/BuySellModals.vue'
import SuccessSell from '../Modals/SuccessBuy.vue'
import {
    infoCrypto,
    infoUnit,
    Loop
} from '@/library/reuseableFunction'
// import crypto from '../../library/crypto.js'
export default {
    name: 'CryptoInfo',
    components: {
        VueTradingView,
        DescribeCrypto,
        AddComment,
        CommentsList,
        BuySellModals,
        SuccessSell
    },
    data() {
        return {
            successData: {},
            prices: [],
            coins: [],
            destCoinSymbol: "BITCOIN",
            percentChange: 0,
            buyPrice: 0,
            sellPrice: 0,
            buySell: false,
            success: false,
            info: [{
                    title: "درصد تغییرات",
                    number: 1.25,
                    change: 1
                },
                {
                    title: "بیشترین",
                    number: 21321313213
                },
                {
                    title: "کمترین",
                    number: 2131313131
                },
                {
                    title: "حجم",
                    number: 798798797987
                }
            ],
            loop: undefined
        }
    },
    computed: {
        infoCrypto,
        infoUnit
    },
    methods: {
        Buybtn() {
            this.destCoinSymbol = this.infoCrypto
            this.buyPrice = this.prices.find(a => a.destCoinSymbol == this.infoCrypto).buyPrice
            this.state.BuyModal = true

        },
        Sellbtn() {
            this.destCoinSymbol = this.infoCrypto
            this.sellPrice = this.prices.find(a => a.destCoinSymbol == this.infoCrypto).sellPrice
            this.state.SellModal = true
        },
        async getPrice() {
            const res = await this.$axios.get('coins/exchange-price/' + (this.infoUnit == 'TOMAN' ? 'usd' : 'usdt') + '?includeWeeklyChange=true')
            this.prices = res
            this.buyPrice = this.prices.find(a => a.destCoinSymbol == this.infoCrypto).buyPrice
            this.sellPrice = this.prices.find(a => a.destCoinSymbol == this.infoCrypto).sellPrice
            this.percentChange = this.prices.find(a => a.destCoinSymbol == this.infoCrypto).price24hrChangePercent
        },
        openSuccessModal(e) {
            this.successData = e
            this.success = true
        },
        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + 'Toman')
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    mounted() {
        this.state.loading = true
        //   this.getPrice()
        this.coins = this.$coins
        this.beforeLoop()
        this.state.loading = false

    }
}
</script>

<style lang="scss" scoped>
.crypto-container {
    background: #F2F4F5;
    padding: 50px 0 50px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 50px;
    flex-wrap: wrap;

    .crypto {
        margin: 0 10px;
        max-width: 1440px;
        background: #FFFFFF;
        flex-grow: 1;
        box-sizing: border-box;
        // padding: 20px;
        border-radius: 3px;
    }
}

.statistics-container {
    display: flex;
    column-gap: 20px;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 20px 10px;
    min-height: 400px;
    flex-wrap: wrap;
    row-gap: 20px;

    .statistics {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        flex-grow: 1;
        justify-content: space-around;
        min-width: 300px;
    }
}

.btns-container {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.buy-btn {
    background: #80CC52;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    font-size: 14px;
    width: 200px;
    height: 44px;
}

.sell-btn {
    background: #D72F70;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    font-size: 14px;
    height: 44px;
    width: 200px;
}

.traiding-container {
    display: flex;
    flex-grow: 30;
    min-width: 300px;
}

.statistics-list,
.title {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}

hr {
    margin: 30px 0;
    background: rgba(61, 73, 204, 0.1);
    border: none;
    height: 1px;
}

.crypto-img {
    width: 50px;
    height: 50px;
}

.statistics-list-title {
    font-size: 14px;
    color: #999999;
}

.statistics-list-number {
    font-size: 18px;
}

.positive-change {}

.name {
    font-size: 24px;
    color: #525CCC;
}

.title-number {
    font-size: 18px;
}

.line {
    position: relative;
    border-bottom: 1px solid rgba(61, 73, 204, 0.1);
    margin: 50px 0;
}

.line-title {
    position: absolute;
    bottom: -15px;
    background: white;
    padding: 0 10px;
    font-size: 22px;
}

.describe-container {
    box-sizing: border-box;
    padding: 20px;
}
</style>
