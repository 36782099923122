import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "@/components/HomePage/HomePage.vue"
import Finnotech from "@/components/Account/Finnotech.vue"
import Information from "@/components/Informations/Information.vue"
import Trade from '@/components/Trade/Trade.vue'
import Account from '@/components/Account/Account.vue'
import Notification from '@/components/Profile/Notifications/Notification.vue'
import FromGoogle from '@/components/Authentication/FromGoogle.vue'
import RedirectFromBank from '@/components/Profile/Wallet/RedirectFromBank.vue'
import BanPage from '@/components/HomePage/BanPage'
Vue.use(VueRouter)
const that = Vue.prototype
const routes = [
  {
    path:'/warnning',
    name: 'Warnning',
    component: BanPage,
  },
    {
        path: '/',
        redirect:'/home',
    },
    // {
    //   path : '/redirect-from-banks',
    //   redirect  :'/redirect-from-banks'
    // },
    {
      path:'/redirect-from-banks',
      name: 'RedirectFromBank',
      component: RedirectFromBank,
    },
    {
      path: '/fromgoogle',
      name: 'FromGoogle',
      component: FromGoogle
  },
    {
      path:'/fino-verifying',
      component:Finnotech,
      name:'FinoVerifying'
    },
    {
      path: '/home',
      component: HomePage,
      name:'Home',
  },
    {
      path: '/faq',
      component: Information,
      name:'FAQ',
    },
    {
      path: '/how-to-use',
      component: Information,
      name:'HowToUse',
    },
    {
      path: '/about',
      component: Information,
      name:'AboutUs',
    },
    {
      path: '/fees',
      component: Information,
      name:'Fees',
    },
    {
      path: '/policy',
      component: Information,
      name:'Policy',
    },
    {
      path: '/online-prices',
      component: HomePage,
      name:'Online',
    },
    {
      path: '/markets',
      component: HomePage,
      name:'Markets',
    },
    {
      path: '/crypto-information',
      redirect: '/crypto-information/TOMAN/BITCOIN',
    },
    // {
    //   path: '/crypto-information/TOMAN/TETHER',
    //   redirect: '/crypto-information/TOMAN/TETHER',
    // },
    {
      path: '/crypto-information/:infoUnit/:infoCrypto',
      component: HomePage,
      name:'CryptoInfo',
      props: true
    },
    {
      path: '/advanced-trade',
      redirect: '/advanced-trade/TOMAN/BITCOIN',
    },
    {
      path: '/advanced-trade/TETHER/TOMAN',
      redirect: '/advanced-trade/TOMAN/TETHER',
    },
    {
      path: '/advanced-trade/TETHER/TETHER',
      redirect: '/advanced-trade/TETHER/BITCOIN',
    },
    {
      path: '/advanced-trade/:tradeTo/:tradeFrom',
      component: Trade,
      name:'AdvancedTrade',
      props: true
    },
    
    {
      path: '/basic-trade',
      redirect: '/basic-trade/TOMAN/BITCOIN',
    },
    {
      path: '/basic-trade/TETHER/TOMAN',
      redirect: '/basic-trade/TOMAN/TETHER',
    },
    {
      path: '/basic-trade/TETHER/TETHER',
      redirect: '/basic-trade/TETHER/BITCOIN',
    },
    {
      path: '/basic-trade/:tradeTo/:tradeFrom',
      component: Trade,
      name:'BasicTrade',
      props: true
    },
    {
      path: '/dashboard',
      component: Account,
      name:'Dashboard',
    },
    {
      path: '/profile',
      component: Account,
      name:'Profile',
    },
    {
      path: '/wallet',
      component: Account,
      name:'Wallet',
    },
    {
      path: '/otc-wallet',
      component: Account,
      name:'OTCWallet',
    },
    {
      path: '/history',
      component: Account,
      name:'History',
    },
    {
      path: '/setting',
      component: Account,
      name:'Setting',
    },
    {
      path: '/notifications',
      component: Account,
      name:'Notifications',
    },
    {
      path: '/invite-friends',
      component: Account,
      name:'InviteFriends',
    },
    // {
    //   path: '/setting',
    //   component: Account,
    //   name:'Setting',
    // },
    {
      path: '/notifications',
      component: Notification,
      name:'Notification',
    },
    
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

const forLogin = [
  'History',
  'Dashboard',
  'Wallet',
  'OTCWallet',
  'Setting',
  'Messages',
  'Profile',
  'Orders',
  'AdvancedTrade',
  // 'Online',
  'BasicTrade'
]
const unAuthorUser = [
  'History',
  'Dashboard',
  'Wallet',
  'OTCWallet',
  'Orders',
  // 'Online'
]
const otp = [
  'TwoStep',
  'ResetPass',
]
const auth = [
  'Login',
  'Register',
  'ForgetPass',
  // 'Online'
]
router.beforeEach(async (to, from, next) => {
  fetch('https://api.ipgeolocation.io/ipgeo?apiKey=3efe9631b4b6482f93a439dc5790df7a').then(response => response.json()).then(data => {
             if(!data.country_name.includes('Iran') ) {
                 this.$router.push('/warnning')
             }
         });
  document.title = to.name
  that.state.loading = true
  if(!that.state.coins){
    await that.$getCoins()
  }
  if (that.state.token) {
    
      if (!that.state.gotten) {
          await that.$getuserInfo()
          that.state.loading = true
          await that.$getFees()
      }

      that.state.loading = true

      if (from.name === 'Blog') {
          await window.open(to.fullPath, '_self')
      } else if (otp.includes(to.name) || auth.includes(to.name)) {
          next({name: 'Home'})
      } else if (to.name === 'Trade') {
          try {
              that.state.loading = true
              await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
              next()
          } catch (e) {
              if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                  if (otp.includes(from.name)) {
                      next({name: 'Home'})
                  } else {
                      await that.$error('بازار غیرفعال است')
                      next(from)
                  }
              }
          }
      } else {
          if (that.state.userInfo.authorized) {
              next()
          } else {
              if (otp.includes(from.name) && to.name !== 'userAccount') {
                  next('/profile')
              } else if (unAuthorUser.includes(to.name)) {
                  that.state.loading = false
                  const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                  if (res.isConfirmed) {
                      next('/profile')
                  } else {
                      next(from)
                  }
              } else next()
          }
      }
  } else {
      if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name)){
        next({name: 'Home'})
          that.state.login = true
      }
          
      else {
          next()
      }
  }
  that.state.loading = false
})
router.afterEach((to) => {
  if(to.name !== 'Online') {
    return new Promise(() => {
      
      setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, 500)
      
  })
  }
    
})
export default router
