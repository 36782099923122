<template>
<div class="table-container">
    <div>
        <table>
            <tr class="tr-head">
                <td>
                    بازار
                </td>
                <td>
                    قیمت
                </td>
                <td>
                    تغییرات
                </td>
            </tr>
            <tr v-if="!loading && !tableContents.length" class="tr-body">
                <td>
                    <p style="width:100%;text-align:center;color:red">در حال حاضر بازار فعالی وجود ندارد</p>
                </td>
            </tr>
            <tr class="tr-body" v-for="(tableContent , index) in filterPrices1" :key="tableContent.price +'1'+ index" @click="goto($coins[tableContent.destCoinSymbol].coin)">
                <td>
                    {{$coins[tableContent.destCoinSymbol].persianName}}
                    <!-- {{$coins}} -->
                </td>
                <td>
                    <span>
                        {{$toLocal(tableContent.price,toman ? 0 : 2)}}
                    </span>
                </td>
                <td :class="{'positive-change':tableContent.price24hrChangePercent > 0 , 'negative-change':tableContent.price24hrChangePercent < 0}">
                    <span v-if="tableContent.price24hrChangePercent < 0">-</span>
                    {{$toLocal(Math.abs(tableContent.price24hrChangePercent),1)}}٪
                    <span v-if="tableContent.price24hrChangePercent > 0">+</span>
                </td>
            </tr>
        </table>
    </div>
    <div>
        <table>
            <tr class="tr-head">
                <td>
                    بازار 
                </td>
                <td>
                    قیمت
                </td>
                <td>
                    تغییرات
                </td>
            </tr>
            <tr v-if="!loading && !tableContents.length" class="tr-body">
                <td>
                    <p style="width:100%;text-align:center;color:red">در حال حاضر بازار فعالی وجود ندارد</p>
                </td>
            </tr>
            <tr class="tr-body" v-for="(tableContent , index) in filterPrices2" :key="tableContent.price +'2'+ index" @click="goto($coins[tableContent.destCoinSymbol].coin)">
                <td>
                    {{$coins[tableContent.destCoinSymbol].persianName}}
                    <!-- {{$coins}} -->
                </td>
                <td>
                    <span>
                        {{$toLocal(tableContent.price,toman ? 0 : 2)}}
                    </span>
                </td>
                <td :class="{'positive-change':tableContent.price24hrChangePercent > 0 , 'negative-change':tableContent.price24hrChangePercent < 0}">
                    <span v-if="tableContent.price24hrChangePercent < 0">-</span>
                    {{$toLocal(Math.abs(tableContent.price24hrChangePercent),1)}}٪
                    <span v-if="tableContent.price24hrChangePercent > 0">+</span>
                </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
// import OverLoading from "@/components/Tools/over-loading";
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: "BasicMarketPrice",
    components: {
        // OverLoading
    },
    props: {
        toman: {
            default: true
        },
        findCoin: {
            default: ''
        }
    },
    data() {
        return {
            tableContents: [],
            tableContents1: [],
            tableContents2: [],
            loading: false,
            loop: ''
        }
    },
    watch: {
        'toman'() {
            this.tab()
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        filterPrices1() {
            let filterPrice = this.tableContents.slice(0, (this.tableContents.length / 2))
            if (this.findCoin) {
                // var persianName = this.$coins[this.destCoinSymbol].persianName
                filterPrice = this.tableContents.filter(a => a.destCoinSymbol.includes(this.findCoin.toUpperCase()) || this.$coins[a.destCoinSymbol].persianName.includes(this.findCoin.toUpperCase())).slice(0, (this.tableContents.length / 2))
            }
            return filterPrice
        },
        filterPrices2() {
            let filterPrice = this.tableContents.slice((this.tableContents.length / 2), (this.tableContents.length))
            if (this.findCoin) {
                // var persianName = this.$coins[this.destCoinSymbol].persianName
                filterPrice = this.tableContents.filter(a => a.destCoinSymbol.includes(this.findCoin.toUpperCase()) || this.$coins[a.destCoinSymbol].persianName.includes(this.findCoin.toUpperCase())).slice((this.tableContents.length / 2), (this.tableContents.length))
            }
            return filterPrice
        }
    },
    methods: {
        async getPrice() {
            const link = this.toman ? '/coins/price/usd' : '/coins/price/usdt'
            let [res, ] = await this.$http.get(link, {
                params: {
                    include24hrChange: true,
                    includeWeeklyChange: false
                }
            })
            this.loading = false
            if (res) {
                this.tableContents = res
                this.sort()
            }
        },
        goto(e) {
            this.$router.push('/basic-trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
                ++this.state.orderChanged
        },
        sort() {
            this.tableContents.sort((a, b) => this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))
            this.tableContents1 = this.tableContents.slice(0, (this.tableContents.length / 2))
            this.tableContents2 = this.tableContents.slice((this.tableContents.length / 2), (this.tableContents.length))
        },
        tab() {
            // this.loop.stop()
            this.tableContents = []
            if (this.state.loop) {
                this.beforeLoop()
            } else {
                this.getPrice()
            }
        },
        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    mounted() {
        this.beforeLoop()
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style lang="scss" scoped>
.tr-head {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #999999;
    height: 28px;
    margin: 4px 0;
}

.tr-body {
    font-weight: normal;
    /* font-size: 12px; */
    font-size: clamp(10px, 1vw, 12px);
    color: #404040;
    height: 24px;
    margin: 4px 0;
    cursor: pointer;
}

.tr-body:hover {
    background: rgba(61, 73, 204, 0.05);
}

.table-container {
    display: flex;
    width: 100%;
    column-gap: 20px;
    flex-wrap: wrap;
    flex-basis: 200px;

    div {
        flex-grow: 1;
        display: flex;
    }
}

table {
    /* width: 100%; */
    /* max-width: 315px; */
    padding: 0 10px;
    text-align: center;
    table-layout: fixed;
    border-collapse: collapse;
    flex-grow: 1;
    min-width: 200px;
}

tr td:last-of-type {
    text-align: left;
    padding-left: 10px;
}

tr td:first-of-type {
    text-align: right;
    padding-right: 10px;
}

.positive-change {
    color: #80CC52;
}

.negative-change {
    color: #D72F70;
    direction: ltr;

}
</style>
