 import Vue from "vue";

Vue.use(require('vue-cookies'))
const that = Vue.prototype
const state = Vue.observable({
  email:'',
  needPass:false,
  pathMatch : 'EMAIL',
  login:false,
  subscribe:false,
  exit : false,
    dark:false,
    neon:false,
    light:true,
    trSell:"rgba(255, 217, 232, 1)",
    trBuy:"rgba(231, 255, 217, 1)",
    tradingTheme:"light",
    twofa:false,
    twofaSMS:false,
    twofaGoogle:false,
    successChange:false,
    authenModal:false,
    activeBuyModal:false,
    activeSellModal:false,
    walletToomanDe:false,
    walletToomanWi:false,
    walletBtcDe:false,
    walletBtcWi:false,
    WalletBtcWiConti:false,
    confirmEmail:false,
    forgetPass:false,
    setPass:false,
    BuyModal:false,
    SellModal:false,
    BuyFirst:true,
    BuyBamboo:true,
    BuyOther:false,
    successBuy:false,
    successSell:false,
    SellFirst:false,
    SellBamboo:false,
    SellOther:false,
    scroll:false,
    // token:'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuaW1hMjE0Mi5wMzBAZ21haWwuY29tIiwiaWF0IjoxNjQ0MjM2OTUyLCJleHAiOjE2NDYzODQ0MzV9.7_W6sXZ8KMkF6hhSQkBEQ_avSN1PgAE2fFmC88mo3so',
    // 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuaW1hMjE0Mi5wMzBAZ21haWwuY29tIiwiaWF0IjoxNjQ0MjM2OTUyLCJleHAiOjE2NDYzODQ0MzV9.7_W6sXZ8KMkF6hhSQkBEQ_avSN1PgAE2fFmC88mo3so'
    exchangeBuyPrice:0,
    exchangeSellPrice:0,
      coins : false,
      orderChange:0,
      token: localStorage.token || Vue.$cookies.get('--abcd14589') || "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2RhdHBhbmFoMkBnbWFpbC5jb20iLCJpYXQiOjE2NTQ1Nzk5MjEsImV4cCI6MTY4NjExNTkyMX0.BLN9uIsQiemluwICs_DdOD5-Hzr_8Cv98vu7e0LTIpc",
      // token:'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2RhdHBhbmFoMkBnbWFpbC5jb20iLCJpYXQiOjE2NDcxNDY1OTgsImV4cCI6MTY0NzE4MjU5OH0.OK0TijY4NLapt9ZLDufERwrYVdn-90MSHAKrwMllKCc',
    loading: false,
    gotten: false,
    loop: true,
    reload:0,
    progress:0,
    towStepAuthenticationType:'',
    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        remember: false,
        towStepAuthenticationType: '',
        authenticationType: '',
        authorized: false,
        pending: false,
        supervisor: false,
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0,
    },
    subscribeInfo:{
      email:"",
      password:"",
      referralCode:""
    },
    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
    },
    ShopType :'limit',
    sellInfo:{
      amount:0
    },
    buyInfo :{
      amount:0
    },

    buyAmount:0,
    buyPrice:0,
    sellAmount:0,
    sellPrice:0
})

that.state = state
export default state
// Vue.prototype.state = Vue.observable({
//     dark:false,
//     neon:false,
//     light:true,
//     trSell:"rgba(255, 217, 232, 1)",
//     trBuy:"rgba(231, 255, 217, 1)",
//     tradingTheme:"light"
// })