<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="$emit('closeLoginModal')">
        <!-- <img class="close-img" src="@/assets/close.png" alt="" @click="closeLogin"> -->
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <svg class="close-svg" width="23" height="22" viewBox="0 0 23 22" fill="black" xmlns="http://www.w3.org/2000/svg" @click="$emit('closeLoginModal')">
                <path d="M20.1625 0.393398L11.2132 9.34272L2.26388 0.393398C1.80622 -0.0642598 1.06426 -0.0642597 0.606602 0.393398C0.148943 0.851057 0.148943 1.59302 0.606602 2.05068L9.55592 11L0.606602 19.9493C0.148943 20.407 0.148943 21.1489 0.606602 21.6066C1.06426 22.0643 1.80622 22.0643 2.26388 21.6066L11.2132 12.6573L20.1625 21.6066C20.6202 22.0643 21.3621 22.0643 21.8198 21.6066C22.2775 21.1489 22.2775 20.407 21.8198 19.9493L12.8705 11L21.8198 2.05068C22.2775 1.59302 22.2775 0.851057 21.8198 0.393398C21.3621 -0.0642597 20.6202 -0.0642597 20.1625 0.393398Z" fill="black" />
            </svg>
            <header class="modal-header" id="modalTitle">
                <slot name="header">
                    <p class="header">ورود</p>
                </slot>
            </header>
            <section class="modal-body" id="modalDescription">
                <slot name="body">
                    <form class="form" @submit.prevent="send">
                        <label for="" class="personal-label">
                            <input class="personal-input" type="text" placeholder="آدرس ایمیل" v-model="data.email">
                            <span class="input-title">
                                آدرس ایمیل
                            </span>
                        </label>
                        <label for="" class="personal-label">
                            <input class="personal-input pass-input" :type="type" placeholder="رمز عبور" v-model="data.password">
                            <span class="input-title">رمز عبور</span>
                            <img class="show-pass" src="@/assets/pass.png" alt="" @click="showPassword">
                            <p class="forget-pass" @click="openForget">
                                فراموشی رمز عبور
                            </p>
                        </label>
                        <!-- <div class="recaptcha-container">
                            <vue-recaptcha sitekey="6Lcy77UfAAAAAEFoc_mRpMRg3wkrjjuWqwjo265S" ref="recaptcha" @verify="verifyMethod" :loadRecaptchaScript="true"></vue-recaptcha>
                        </div> -->
                        <!-- <a href="/home-page" @click.prevent="$router.push('/dashboard')"> -->
                        <button class="sub-btn" type="submit" :disabled="!data.email || !data.password || !recaptch">
                            ورود
                        </button>
                        <!-- </a> -->
                    </form>
                    <div class="or-div">
                        <span>
                            یا
                        </span>
                    </div>
                </slot>

            </section>

            <footer class="modal-footer">
                <!-- <div class="google-btn" @click="googleSso">
                    <img class="google-img" src="@/assets/google.png" alt="">
                    <span>
                        ادامه با گوگل
                    </span>
                </div> -->
                <p>حساب کاربری ندارید؟<span class="sub_link" @click="state.login=false;state.subscribe=true"> ثبت نام کنید </span>
                </p>
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
// const goto = {
//     EMAIL: '/two-step-email',
//     SMS: '/two-step-sms',
//     GOOGLE_AUTHENTICATOR: '/two-step-google-auth',
// }
// import VueRecaptcha  from 'vue-recaptcha';
export default {
    name: 'HomePage',
    components: {
        // VueRecaptcha
    },
    data() {
        return {
            type: 'password',
            close: false,
            recaptch:true,
            data: {
                email: '',
                password: '',
                osType: '',
                deviceType: '',
                browser: '',
            },
            emailError: '',
            passError: ''
        }
    },

    methods: {
        onEvent() {
        // when you need a reCAPTCHA challenge
        this.$refs.recaptcha.execute();
      },
      verifyMethod() {
          this.recaptch = true
      },
        showPassword(e) {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'Hide Password'
            } else {
                this.type = 'password'
                this.btnText = 'Show Password'
            }
            e.preventDefault();
        },
        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            await this.checkEmail()
            this.state.loading = true
            const res = await this.$axios.post('/users/login', this.data)
            if (res.message === 'login successful, otp is required') {

                this.$cookies.set('username', this.data.email)
                this.state.userInfo.email = this.data.email
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                this.state.pathMatch = res.baseDTO.towStepAuthenticationType
                this.state.login = false
                this.state.confirmEmail = true
                // await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])

            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                // await this.$router.push('/useraccount')
            }

        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
        openForget() {
            this.state.forgetPass = true
            this.state.needPass = true
            this.$emit('closeLoginModal');
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 470px;
    max-width: 95%;
    position: relative;
}

.close-svg {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 111;
}

.modal-header {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    justify-content: center;
}

.modal-body,
.modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    direction: rtl;
    width: 100%;
}

input {
    border: 1px solid rgba(61, 73, 204, 0.5);
    box-sizing: border-box;
    border-radius: 3px;
    min-height: 44px;
    margin: 10px 0;
    padding: 0 10px;
    width: 100%;
    outline: none;
    color: #3D49CC;
}

input::placeholder {
    color: rgba(61, 73, 204, 0.5);
}

label {
    position: relative;
    width: 100%;
    height: 60px;
    margin: 10px 0;
}

/* label span {
    position: absolute;
    top: 3px;
    right: 10px;
    background-color: white;
    padding: 0 5px;
    font-weight: normal;
    font-size: 10px;
    color: #3D49CC;

  } */
.personal-label span {
    top: 0 !important;
}
.personal-label {
    margin-bottom: 15px;
}

.forget-pass {
    position: absolute;
    top: 45px;
    left: 0;
    font-weight: normal;
    font-size: 12px;
    color: rgba(61, 73, 204, 0.5);
    cursor: pointer;
}

.sub-btn {
    background: #3D49CC;
    border-radius: 3px;
    height: 44px;
    color: white;
    border: none;
    margin-top: 20px;
    width: 100%;
}

.or-div {
    border-top: 1px solid rgba(61, 73, 204, 0.1);
    position: relative;
    width: 100%;
    height: 20px;
    margin: 20px 0 0 0;
}

.or-div span {
    position: absolute;
    top: -10px;
    right: 50%;
    background-color: white;
    padding: 0 5px;
    color: #3D49CC;
    font-weight: normal;
    font-size: 12px;
}

.google-btn {
    border: 1px solid #3D49CC;
    box-sizing: border-box;
    border-radius: 3px;
    height: 44px;
    width: 100%;
    direction: rtl;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.google-img {
    position: absolute;
    right: 10px;
}

.show-pass {
    width: 20px;
    height: 15px;
    position: absolute;
    top: 23px;
    right: 10px;
    cursor: pointer;
}

.header {
    font-weight: normal;
    font-size: 30px;
    color: #3D49CC;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

input:focus {
    border: 1px solid rgba(61, 73, 204, 1);
}

.pass-input {
    /* text-align: left; */
    padding-right: 50px;
    box-sizing: border-box;
}

.sub_link {
    color: #3D49CC;
    border-bottom: 1px solid #3D49CC;
    cursor: pointer;
}

.close-img {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
}
.recaptcha-container {
    padding: 10px;
}
</style>
