var crypto=[
    
    {
        "name":"بیت کوین",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":-0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/btc.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"اتريوم",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/eth.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"بیت کوین کش",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":-0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/bitc.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"اتریوم کلاسیک",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/ethc.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"لایت کوین",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/ltc.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"ریپل",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/rip.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"دش کوین",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":-0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/dsh.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"دوج کوین",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/dog.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"ترون",
        "ENname":"ETR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/trn.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    },
    {
        "name":"تتر",
        "ENname":"TTR",
        "price":875500,
        "MaxPriceTooman":36456981,
        "MinPriceTooman":34987456,
        "MaxPriceTeter":756,
        "MinPriceTeter":740,
        "change":0.45,
        "exchange":11213246587,
        "image":require('../assets/Coins/ttr.png'),
        "amount":0.5456,
        "date":"xxxx/xx/xx",
        "total":6546546545,
        "priceTether":0.45
    }
]
export default crypto