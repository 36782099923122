var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-trade",class:{'dark':_vm.state.dark , 'light':_vm.state.light , 'neon':_vm.state.neon}},[_c('div',{staticClass:"trade"},[_c('div',{staticClass:"chart-range",class:{'dark':_vm.state.dark , 'light':_vm.state.light , 'neon':_vm.state.neon}},[_c('div',{staticClass:"chart"},[_c('vue-trading-view',{key:_vm.state.tradingTheme+ _vm.$coinUnit[_vm.$route.params.tradeFrom],staticStyle:{"height":"270px"},attrs:{"options":{
                            height:'600',
                             symbol: _vm.tradeFrom !== 'TETHER' ? 'BINANCE:' + _vm.$coinUnit[_vm.tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
                            autosize:true,  
                            theme:("" + (_vm.state.tradingTheme)),  
                                    timezone: 'Asia/Tehran',
                                    style: '2',
                                    hide_top_toolbar:true,
                                    hide_legend:true,
                                    hide_bottom_toolbar:true,
                                    hide_legend:true,
                                    locale: 'fa_IR',
                                    enable_publishing: false,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: true,
                                    allow_symbol_change: true,
                                    save_image : false,
                                    enabled_features: [ 'header_fullscreen_button' ],
                                    disabled_features: [ 'border_around_the_chart' , 'remove_library_container_border' ],
                                        loading_screen:{ backgroundColor:'#FFFFFF', foregroundColor: '#FFFFFF' , }, 
                                        overrides: { background: '#FFFFFF' } }}})],1),_c('div',{staticClass:"range",class:{'dark-child':_vm.state.dark , 'light-child':_vm.state.light , 'neon-child':_vm.state.neon}},[_c('BasicRange',{key:'basictRange'+_vm.state.orderChanged + _vm.$route.params.tradeTo + _vm.$route.params.tradeFrom})],1)]),_c('div',{staticClass:"exchange-container"},[_c('TradeTitle'),_c('BasicMarkets'),_c('Exchange',{key:'ExchangeBasic' + _vm.state.orderChanged + _vm.$route.params.tradeTo + _vm.$route.params.tradeFrom})],1)]),_c('History',{key:_vm.$route.params.tradeTo + _vm.$route.params.tradeFrom})],1)}
var staticRenderFns = []

export { render, staticRenderFns }