<template>
    <div >
        <p class="title">
            بیت‌کوین چیست؟
        </p>
        <p class="describe">
            بیت‌کوین یک ارز دیجیتال غیرمتمرکز، بدون بانک مرکزی یا مدیریت واحد است که می‌تواند بدون نیاز به واسطه، از کاربر به کاربر دیگر از طریق شبکه بیت‌کوین همتا به همتا ارسال شود. تراکنش‌ها توسط یک شبکه به صورت رمزنگاری‌شده تأیید می‌شود و در یک دفتر کل توزیع‌شده عمومی به نام بلاک‌چین ثبت و ضبط می‌شوند. این رمزارز در سال ۲۰۰۸ توسط شخص یا گروهی ناشناس با نام ساتوشی ناکاموتو اختراع شد.[۵] استفاده از این ارز در سال ۲۰۰۹[۶] پس از انتشار نرم‌افزار متن‌باز آن آغاز شد.

در فرایند استخراج رمزارز، به فرد استخراج کننده پاداش بیت کوین می‌رسد که می‌توان با بیت کوین، ارزهای دیگر، محصولات و خدمات را خرید. به دلیل استفاده در تراکنش‌های غیرقانونی، مصرف مقدار زیادی برق در استخراج ، نوسان قیمت و دزدی از مبادلات، بیت کوین مورد انتقاد قرار گرفته‌است. در گذشته برخی از سرمایه گذاران و اقتصاددانان از بیت کوین را به عنوان یک حباب سوداگرانه توصیف کرده‌اند ولی برخی از آن به عنوان سرمایه‌گذاری استفاده کرده‌اند. چندین آژانس نظارتی هشدارهایی برای سرمایه گذاران در مورد بیت کوین صادر کرده‌اند. در سپتامبر ۲۰۲۱، السالوادور رسماً بیت کوین را به عنوان ارز قانونی پذیرفت و نخستین کشوری بود که این کار را انجام داد.[۱۰]

کلمه بیت کوین در یک اوراق سفید منتشر شده در ۳۱ اکتبر ۲۰۰۸ تعریف شد. که ترکیبی از بیت و سکه است. هیچ استاندارد واحدی برای نوشتن کلمه بیت کوین وجود ندارد. برخی منابع از بیت کوین  با حروف بزرگ برای اشاره به فناوری و شبکه رایانه‌ای و بیت کوین  با حروف کوچک برای واحد حساب استفاده می‌کنند. روزنامه وال‌استریت جورنال, و فرهنگ انگلیسی آکسفورد از نوشتن بیت کوین با حروف کوچک در همه موارد حمایت می‌کنند
        </p>
        <img class="img-blog" src="@/assets/blogcoin.png" alt="">
        <p class="title">
            خالق بیت‌کوین کیست؟
        </p>
        <p class="describe">
از ابتدای خلق بیت کوین به عنوان اولین ارز رمزگذاری شده، هویت ناشناس سازنده که با نام مستعار ساتوشی ناکاموتو به جوامع علمی معرفی شده، مجهول بوده است. اما از سال ۲۰۱۶ یک برنامه ‎نویس و مدیر مدعی شد که ساتوشی است.
        </p>
         <p class="title">
           ساتوشی ناکاموتو کیست؟
        </p>
        <p class="describe">
            بیت‌کوین، در سال ۲۰۰۸ میلادی، با انتشار مقاله‌ای از سوی ساتوشی ناکاموتو، معرفی شد، فردی که ادعا می‌شد ژاپنی است، متولد سال ۱۹۷۵ میلادی است و ۴۶ سال دارد. او نخستین بار در سال ۲۰۰۸ میلادی از بیت‌کوین و ساز و کار آن به‌عنوان یک «رمزارز» صحبت کرد، فرزندی که در واقع خودش خلق کرده بود و سعی داشت در قالب توضیحاتی، شیوه کارکرد و فلسفه‌اش را به بقیه نشان بدهد.

تا سال ۲۰۱۰، ساتوشی ناکاموتو همچنان از بیت کوین حمایت می‌کرد و در جریان اصلاح کد نویسی اولیه هم فعال بود. با این همه، از همان سال به بعد، این نابغه مرموز ناپدید شد و کسی نمی‌داند حالا بین کوین به ۱۳ سالگی رسیده، ناکاموتو کجاست یا شخصاً چقدر بیت‌کوین در اختیار دارد.
        </p>
    </div>
    
</template>

<script>
import {
    infoCrypto,
} from '@/library/reuseableFunction'
export default {
  name: 'DescribeCrypto',
  components :{
    
  },
  data() {
      return {
          content : ''
      }
  },
  computed : {
      infoCrypto
  },
  methods: {
      async getData() {
          this.state = true
            const res = await this.$axios('/blog')
            this.blogs = res.content
            if(this.blogs.find(a => a.title == this.infoCrypto)) {
                this.getContent()
            }
           this.state = false

      },
      async getContent() {
          this.state = true
            const res = await this.$axios('/blog' , {
                params:{
                    title:this.infoCrypto
                }
            })
            this.content = res.baseDTO.body
           this.state = false

      }
  },
  mounted() {
      this.getData()
  },
}
</script>

<style scoped>
    .title {
        font-size: 22px;
    }
    .describe {

    }
    img {
        display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    }
    .img-blog {
        max-width: 100%;
    }
</style>