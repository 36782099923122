<template>
<div class="login-signup">
    <div class="form-container">
        <div class="input-container">
            <p>
                استفاده از سایت  <span class="name">نوتیبای</span>  فقط با آی پی ایران مقدور است
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "BanPage",
    components: {
        
    },
    data() {
        return {
            
        }
    },
}
</script>

<style lang="scss" scoped>
.login-signup {
    // background-image: url('../../assets/Home/login.png');
    width: 100%;
    height: calc(100vh - 48px);
    display: flex;
    box-sizing: border-box;
    padding: 56px 10px 10px 10px;
    justify-content: center;
    background-image: url('../../assets/baner.png');
    background-color: #3D49CC;
    //  align-items: center;
    background-repeat: no-repeat;
    background-size: cover;

    .form-container {
        // max-width: 384px;
        min-width: 300px;
        width: 100%;
        max-height: 571px;
        background: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 0 16px 16px 24px;
        height: max-content;

        .title-container {
            height: 107px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.header {
    font-size: 20px;
    color: black;
    text-align: center;
}
.name {
    color: #80cc52;
}
.input-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    //   margin-bottom: 40px;
    padding: 15px 0;
    justify-content: space-between;
    row-gap: 20px;
    row-gap: 40px;
}

.describe {
    font-size: 12px;
    color: black;
}

.deActive-btn,
.active-btn {
    border-radius: 6px;
    border: none;
    color: #FCFCFC;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    height: 44px;
}

.active-btn {
    background: #3D49CC;
    border-radius: 3px;
}

.deActive-btn {
    background: rgb(61, 73, 204 , 0.5);
    border-radius: 3px;
}

.footer {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
}

.goBack {
    color: rgba(61, 73, 204, 0.6);
    text-decoration: underline;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0;
    margin-top: 25px;
    cursor: pointer;
}
</style>
