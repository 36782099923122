<template>
    <div class="baner">
        <div class="container">
            <div class="title-contaner">
                <span class="title">
                    صرافی
                </span>
                <span class="name">
                    نوتیبای
                </span>
            </div>
            <div>
                <p class="head">امکان خرید و فروش بیت کوین و سایر ارزهای دیجیتال در محیطی امن و حرفه‌ای </p>
            </div>
            <div class="subscribe">
                <input type="text" name="" placeholder="آدرس ایمیل" class="sub-input" v-model="email">
                <button class="sub-btn" @click="state.subscribe = true;state.subscribeInfo.email= email">
                    ثبت نام
                </button>
            </div>
            <div class="feature">
                <a :href=feature.href @click.prevent="feature.clickFun" v-for="feature , index in feature" :key="index">
                    <p class="feature-p">
                        {{feature.title}}
                    </p>
                </a>
            </div>
            <div class="slides">
                <!-- <GlideSlider/> -->
                <carousel/>
            </div>
        </div>
    </div>
</template>

<script>
// import GlideSlider from './GlideSlider.vue'
import Carousel from './Carousel.vue'
export default {
  name: 'Baner',
  components :{
    Carousel,
    // GlideSlider
  },
  data() {
      return {
          email : '',
          feature : [
              {
                  title:"صرافی آنلاین",
                  href:"/online-prices",
                  clickFun : ()=> {this.$router.push('/online-prices');this.state.scroll = !this.state.scroll}
              },
              {
                  title:"معامله آسان",
                  href:"/basic-trade",
                  clickFun : ()=> {this.$router.push('/basic-trade')}
              },
              {
                  title:"معامله حرفه ای",
                  href:"/advanced-trade",
                  clickFun : ()=> {this.$router.push('/advanced-trade')}
              }
          ]
      }
  },}
</script>

<style lang="scss" scoped>
    .baner {
        background-image: url('../../assets/baner.png');
        min-height: 787px;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        background-color: #3D49CC;
        background-position: center;
        flex-grow: 1;
            .container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 1440px;
                    .title-contaner {
                        margin-top: 20px;
                            .title , .name {
                                font-weight: bold;
                                font-size: 36px;
                            }
                            .title {
                                color: white;
                            }
                            .name {
                                color: #80CC52;
                            }
                    }
                    .head {
                        font-weight: normal;
                        font-size: 22px;
                        font-size: clamp(16px , 1.5vw , 22px);
                        color: #FFFFFF;
                        text-align: center;
                    }
                    .subscribe {
                        display: flex;
                        column-gap: 10px;
                        justify-content: center;
                        align-items: center;
                        margin: 30px 0;
                        flex-wrap: wrap;
                        row-gap: 10px;
                            .sub-input {
                                max-width: 380px;
                                height: 50px;
                                border-radius: 3px;
                                border: none;
                                max-width: 380px;
                                box-sizing: border-box;
                                width: 90vw;
                            }
                            .sub-btn {
                                background: #80CC52;
                                border-radius: 3px;
                                width: 100px;
                                height: 50px;
                                border: none;
                                font-weight: bold;
                                color: #FFFFFF;
                            }
                    }
                    .feature  {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;
                        flex-wrap: wrap;
                        column-gap: 20px;
                             p {
                                color: #FFFFFF;
                                opacity: 0.8;
                                font-weight: normal;
                                font-size: clamp(22px , 2vw , 28px);
                            }
                            .feature-p {
                                width: 250px;
                                height: 100px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 3px;
                            }
                            .feature-p:hover {
                                background: rgba(255, 255, 255, 0.1);
                                backdrop-filter: blur(10px);    
                            }
                    }
                    .slides {
                        margin-top: 30px;
                        width: 100%;
                        max-width: 1200px;
                        overflow: hidden;
                    }
            }
    }
    @supports not (backdrop-filter: blur()) {
        .feature-p:hover {
            background: rgba(255, 255, 255, 0.1); 
        }
    }
</style>
