<template>
    <div class="from-google">
        <p style="color:green;opacity:0.8;font-size:18px">{{status}}</p>
    </div>
</template>

<script>

    export default {
        name: 'fromGoogle',
        data() {
            return {
                data: {
                    osType: '',
                    browser: '',
                    deviceType: ''
                },
                status: 'لطفا شکیبا باشید ...',
            }
        },
        mounted() {
            this.getData()
            this.check()
        },

        methods: {
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
            async check() {
                this.state.loading = true
                try {
                    const res = await this.$axios.post(`/users/get-google-token?browser=${this.data.browser}&osType=${this.data.osType}&deviceType=${this.data.deviceType}&code=${this.$route.query.code}`)
                    this.status = 'با موفقیت وارد شدید.'
                    this.$setToken(res.token)
                    await this.$router.push('/profile')
                } catch (err) {
                    if (err.response.data.message === "GOOGLE_FAILURE") {
                        await this.$error('خطای ورود، به صفحه قبل منتقل میشوید', '')
                        this.$router.push({name: 'Login'})

                    }
                }
            }
        }
    }
</script>

<style scoped>
    .from-google {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
