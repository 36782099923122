<template>
<transition name="modal-fade" >
    <div class="modal-backdrop" @click.self="state.exit = false">
        <div class="modal">
            <!-- <img @click="closeModale" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt=""> -->
            <span class="message-title">
                میخواهید خارج شوید؟
            </span>
            <div class="btns">
                <a href="/" @click.prevent="$router.push('/')">
                    <button class="yes" @click="logOut">
                        بله
                    </button>
                </a>
                <button class="no" @click="closeModale">
                    خیر
                </button>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'ExitModal',
    components: {

    },
    data() {
        return {
            close: false
        }
    },
    methods: {
        closeModale() {
            this.$emit('closeModal', this.close)
        },
        logOut() {
            this.$logout()
            this.state.exit = false
            // this.$router.push('/login')
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
    min-height: 140px;
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    position: relative;
    padding: 24px;
}

.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.message-title {
    font-weight: bold;
    font-size: 24px;
    color: #404040;
}

.send-btn {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    width: 202px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    column-gap: 16px;
    margin-top: 35px;
}




.yes,
.no {
    height: 40px;
    width: 150px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
}

.btns {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    width: 100%;
    align-items: flex-end;
}

.no {
    background:  #D72F70;;
    color: #FCFCFC;
}

.yes {
    background: transparent;
    border: 2px solid  #D72F70;
    color:  #D72F70;
}
</style>
