<template>
    <div class="message-container">
        <div class="message" v-for="messageList , index in messageList.filter(a => !a.commentId)" :key="index">
            <div class="heade">
                <div class="person">
                    <!-- <img class="avatar" :src=messageList.img alt=""> -->
                    <span>
                        {{messageList.name}}
                    </span>
                </div>
                <span>
                    {{messageList.date}}
                </span>
            </div>
            <div class="body">
                {{messageList.text}}
            </div>
            <div class="footer">
                <div class="answer-icon" @click="reply(messageList.id)"> 
                    <img src="@/assets/answer.svg" alt="">
                    <span class="footer-p">
                        پاسخ 
                    </span>
                </div>
                <div class="liks-container">
                    <div>
                        <span class="footer-p">
                            {{messageList.dislikes}}
                        </span>
                        <img src="@/assets/disLike.png" alt="" @click="dislikeFun(messageList.id)">
                    </div>
                    <div>
                        <span class="footer-p">
                            {{messageList.likes}}
                        </span>
                        <img src="@/assets/like.png" alt="" @click="likeFun(messageList.id)">
                    </div>
                </div>
            </div>
            <AddComment v-if="messageList.id==selectedId" :reply='messageList.id'/>
            <template v-if="messageList.replies">
                <div class="answer"  v-for="answer , indexA in messageList.replies" :key="indexA">
                <div class="heade">
                    <div class="person">
                        <!-- <img class="avatar" :src=answer.img alt=""> -->
                        <span>
                            {{answer.name}}
                        </span>
                    </div>
                    <span>
                        {{answer.date}}
                    </span>
                </div>
                <div class="body">
                    {{answer.text}}
                </div>
                <div class="footer">
                    <div>
                        <!-- <img src="@/assets/answer.svg" alt="">
                        <span class="footer-p">
                            پاسخ
                        </span> -->
                    </div>
                    <!-- <div class="liks-container">
                        <div>
                            <span class="footer-p">
                                {{answer.dislikes}}
                            </span>
                            <img src="@/assets/disLike.png" alt="" @click="unlikeFunAns(index)">
                        </div>
                        <div>
                            <span class="footer-p">
                                {{answer.likes}}
                            </span>
                            <img src="@/assets/like.png" alt="" @click="likeFunAns(index)">
                        </div>
                    </div> -->
                </div>
            </div>
            </template>

            <hr>
        </div>
    </div>
</template>

<script>
import {
    infoCrypto,
} from '@/library/reuseableFunction'
import AddComment from './AddComment.vue'
export default {
  name: 'CommetsList',
  components :{
      AddComment
  },
  data() {
      return {
          messageList: [],
          selectedId:0,
        replyComponent : false,
        data : {
            id : 0,
            type : ''
        }
      }
  },
  computed: {
    infoCrypto
  },
//   watch:{
//       'messageList'() {
//           alert('a , b')
//       }
//   },
  methods : {
      async getData() {
          this.state = true
            const res = await this.$axios('/blog')
            this.blogs = res.content
            if(this.blogs.find(a => a.title == this.infoCrypto)) {
                this.getComments()
            }
           this.state = false

      },
      async getComments() {
        //   this.state.loading = true
              const res = await this.$axios('/blog/comment' , {
              params : {
                  title : this.infoCrypto
              }
          })
          this.messageList = res.content
          this.state.loading = false
      },
      async reply(e) {
          if(this.selectedId !== 0) {
              this.selectedId = 0
          }
          else
          this.selectedId = e
      },
      async likeFun(e) {
          this.data.id = e
          this.data.type = 'like'
           await this.$axios.post('/blog/comment-replies/likes-dislikes' , this.data)
           this.getComments()
      },
      async dislikeFun(e) {
          this.data.id = e
          this.data.type = 'dislike'
         await  this.$axios.post('/blog/comment-replies/likes-dislikes' , this.data)
          this.getComments()
      },
      likeFunAns(index) {
          this.messageList[index].answer[0].like ++ 
        // console.log(this.messageList[index].answer[0].like)
      },
      unlikeFunAns(index) {
          this.messageList[index].answer[0].unlike ++ 
      }
  },
  mounted() {
      this.getData()
  },
}
</script>

<style lang="scss" scoped>
    .message , .answer{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    .heade {
        display: flex;
        align-items: center;
        justify-content: space-between;
            .person {
                display: flex;
                align-items: center;
                column-gap: 15px;
            }
    }
    .answer-icon {
        cursor: pointer;
    }
    .avatar {
        width: 50px;
        height: 50px;
    }
    .body {
        font-size: 14px;
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .liks-container {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }
    .footer-p {
        color: #BDBDBD;
        font-size: 14px;
    }
    hr {
        border: none;
        background: rgba(64, 64, 64, 0.1);
        height: 1px;
        width: 100%;
    }
    .answer {
        box-sizing: border-box;
        padding: 0 50px;
    }
</style>