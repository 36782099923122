<template>
<div class="footer">
    <div class="footer-container">
        <div class="information">
            <div class="company">
                <div>
                    <img class="bamboo-img" src="@/assets/bamboo.png" alt="">
                    <p class="company-name">
                        صرافی
                        <span class="bamboo-name">
                            نوتیبای
                        </span>
                    </p>
                </div>
                <div>
                    <p class="describe">
                       این صرافی با ارائه خدمات متنوع و پشتیبانی 24 ساعته، پلتفرمی امن برای مبادلات ارزهای دیجیتال است.
                    </p>
                </div>
                <div>
                    <img src="@/assets/social1.svg" alt="">
                    <img src="@/assets/whatsapp.svg" alt="">
                    <img src="@/assets/facebook.svg" alt="">
                    <img src="@/assets/instagram.svg" alt="">
                </div>
            </div>
            <div class="access">
                <p class="footers-title">
                    دسترسی سریع
                </p>
                <p class="footers-subtitle" @click="$router.push('/online-prices')">
                    صرافی آنلاین
                </p>
                <p class="footers-subtitle">
                    ایزی تریدر
                </p>
                <p class="footers-subtitle" @click="$router.push('/advanced-trade')">
                    معاملات حرفه ای
                </p>
                <p class="footers-subtitle" @click="$router.push('/policy')">
                    قوانین و شرایط
                </p>
            </div>
            <div class="guid">
                <p class="footers-title"  >
                    راهنما
                </p>
                <p class="footers-subtitle"  @click="$router.push('/faq')">
                    سوالات متداول
                </p>
                <p class="footers-subtitle" @click="$router.push('/how-to-use')">
                    راهنمای استفاده
                </p>
                <p class="footers-subtitle" @click="$router.push('/fees')">
                    کارمزد ها
                </p>
                <p class="footers-subtitle">
                    بلاگ نوتیبای
                </p>
                <p class="footers-subtitle" @click="$router.push('/about')">
                    درباره ما
                </p>
            </div>
            <div class="contact">
                <p class="footers-title">
                    تماس با ما
                </p>
                <div class="adress">
                    <img src="@/assets/location.svg" alt="">
                    <p>
                        {{info.address}}
                    </p>
                </div>
                <div class="phone">
                    <img src="@/assets/phone.svg" alt="">
                    <p>
                        {{info.number}}
                    </p>
                </div>
                <div class="email">
                    <img src="@/assets/mail.svg" alt="">
                    <p>
                        {{info.email}}
                    </p>
                </div>
            </div>
        </div>
        <div class="vista">
            <p>
                تمام حقوق برای شرکت ویستا محفوظ میباشد
            </p>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HomePage',
    components: {

    },
    data() {
        return {
            info: {
                address: "",
                appAndroid: "",
                appIOS: "",
                email: "",
                instagram: "",
                linkedin: "",
                number: "",
                telegram: "",
                twitter: ""
            }
        }
    },

    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info?language=PERSIAN')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style scoped>
.footer {
    width: 100%;
    min-height: 300px;
    background: #3D49CC;
}

.footer-container {
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
}

.information {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* column-gap: 174px; */
    column-gap: 40px;
    flex-wrap: wrap;
}

.vista>p {
    font-weight: normal;
    font-size: 12px;
    color: #E0E0E0;
}

.company {
    width: 16vw;
    min-width: 200px;
}

.company>div {
    display: flex;
    column-gap: 20px;
}

.bamboo-img {
    width: 65px;
    height: 65px;
}

.company-name {
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.bamboo-name {
    color: #80CC52;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.footers-title {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    margin: 15px 0;
}

.footers-subtitle {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 5px 0;
    cursor: pointer;
}

.adress p,
.phone p,
.email p {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.adress,
.phone,
.email {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

.guid,
.access {
    /* min-width: 100px; */
}

.contact {
    /* min-width: 300px; */
}
</style>
