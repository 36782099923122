<template>
    <div>
        <div class="add-comment">
            <div class="personal">
                <label for="" class="personal-label">
                    <input class="personal-input" type="text" placeholder="نام و نام خانوادگی" v-model="data.name">
                    <span class="input-title">نام و نام خانوادگی</span>
                </label>
                <label for="" class="personal-label">
                    <input class="personal-input" type="text" placeholder="آدرس ایمیل" v-model="data.email">
                    <span class="input-title">آدرس ایمیل</span>
                </label>
            </div>
            <div class="message">
                <label for="" class="personal-label">
                    <textarea class="personal-input" name="" id="" cols="30" rows="10" placeholder="متن نظر" style="resize:none" v-model="data.text"></textarea>
                    <span class="input-title">متن نظر</span>
                </label>
            </div>
            
        </div>
        <div class="btn-container">
            <button class="add-btn" :disabled='!disable' @click="send">
                ثبت نظر
            </button>
        </div>
        <div>
            
        </div>
    </div>
</template>

<script>
import {
    infoCrypto,
} from '@/library/reuseableFunction'
export default {
  name: 'AddComment',
  components :{
    
  },

  props : ['reply'],

  data() {
      return {
          data :{
              email : '',
              name : '',
              text : '',
              blogTitle : this.infoCrypto,
              commentId : '',
              isValid:false
          }
      }
  },
  computed:{
      disable(){
          if(!this.data.email || !this.data.name || !this.data.text) {
              return false
          }
          else return true
      },
      infoCrypto
  },
  methods : {
      async send() {
          this.data.blogTitle = this.infoCrypto
          if(this.reply) {
              this.data.commentId = this.reply
          }
          else {
              delete this.data.commentId
          }
          
          await this.$axios.post('/blog/comment' , this.data)
          this.data.email = ''
          this.data.name = ''
          this.data.text = ''
      }
  }
}
</script>

<style scoped>
    .add-comment {
        display: flex;
        column-gap: 20px;
        flex-wrap: wrap;
    }
    .personal {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        flex-grow: 1;
    }
    /* input {
        width: 100%;
        max-width: 500px;
    } */
    .message {
        flex-grow: 1;
    }
    textarea {
        width: 100%;
        height: 108px;
        padding: 10px;
        box-sizing: border-box;
    }
    .add-btn {
        margin: 30px 0;
        background: #3D49CC;
        border-radius: 3px;
        border: none;
        font-size: 14px;
        color: #FFFFFF;
        width: 175px;
        height: 44px;
    }
    .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>