<template>
<transition name="modal-fade">
    <div id="modal" class="modal-backdrop" @click.self="$emit('close')">
        <!-- <over-loading v-if="loading"/> -->
        <div class="modal" role="dialog" aria-labelledby="WithdrawModal" aria-describedby="WithdrawModal">
            <img src="@/assets/close.svg" class="btn-close" @click="close" aria-label="Close modal" alt="">
            <header class="modal-header">
                <!-- ثبت درخواست -->
                در خواست<span>{{typeLabel[type]}}</span> به شرح زیر ثبت شود؟
            </header>
            <section class="modal-body" style="direction: rtl">
                <!-- <a class="row justify-content-center" style="font-size: 16px">
                    در خواست<a style="font-weight: bold; margin: 0 3px">{{typeLabel[type]}}</a> به شرح زیر ثبت شود؟
                </a> -->
                <div>
                    <span>قیمت واحد <span>({{$coins[$route.params.tradeTo].persianName}})</span></span>
                    <span>{{unitPrice}}</span>
                </div>
                <div>
                    <span>مقدار {{$coins[$route.params.tradeFrom].persianName}} </span>
                    <span>{{amount}}</span>
                </div>
                <div>
                    <span>حد توقف {{$coins[$route.params.tradeFrom].persianName}} </span>
                    <span>{{stopPrice}}</span>
                </div>
                <div>
                    <span>قیمت کل <span>({{$coins[$route.params.tradeTo].persianName}})</span></span>
                    <span>{{totalPrice}}</span>
                </div>
                <div v-if="date">
                    <span>تاریخ انقضا</span>
                    <span>{{$G2J(date,'faDateTime')}}</span>
                </div>
            </section>
            <footer class="modal-btn">
                <!-- <button type="button" class="normal-btn" @click="accept">بله</button>
                <button type="button" class="normal-btn" @click="close">خیر</button> -->
                <button class="confirm-btn" :class="{'sell-btn' : type=='SELL' , 'buy-btn' : type=='BUY'}" @click.prevent="accept">{{(type=='BUY' ? 'خرید'  : 'فروش' )}} </button>
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
// import OverLoading from "@/components/Tools/over-loading";

const typeLabel = {
    BUY: 'خرید',
    SELL: 'فروش',
}
export default {
    name: "ShopModal",
    components: {
        // OverLoading
    },
    props: ['totalPrice', 'unitPrice', 'amount', 'type', 'date', 'limit','stopPrice'],
    data() {
        return {
            twostepcode: '',
            walletaddress: '',
            typeLabel,
            loading: false,
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async accept() {
            this.loading = true;
            let orderDetail;
            let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo

            if (this.limit == 'limit') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `LIMITED_${this.type}`,
                    expiresAt: this.date || null,
                    unitPrice: this.$S2N(this.unitPrice),
                    wholePrice: this.$S2N(this.totalPrice),
                }
            } 
            else if(this.limit == 'stopLimit') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `STOP_LIMIT_${this.type}`,
                    expiresAt: this.date || null,
                    unitPrice: this.$S2N(this.unitPrice),
                    stopPrice: this.$S2N(this.stopPrice),
                    wholePrice: this.$S2N(this.totalPrice),
                }
            }
            else if(this.limit == 'OCO') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `OCO_${this.type}`,
                    expiresAt: this.date || null,
                    unitPrice: this.$S2N(this.unitPrice),
                    stopPrice: this.$S2N(this.stopPrice),
                    wholePrice: this.$S2N(this.totalPrice),
                }
            }
            else {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `MARKET_${this.type}`,
                }
            }
            try {
                await this.$axios.post(`/orders/${this.type.toLowerCase()}`, orderDetail)
                this.loading = false
                this.$emit('close')
                this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
            } catch (error) {
                this.loading = false
                this.$emit('close')
                if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                    await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: var(--background-child);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 400px;
    // min-height: 470px;
    max-width: 95%;
    position: relative;
    padding: 20px;
}

.modal-header {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: var(--font-color);
}

.btn-close {
    position: absolute;
    left: 15px;
    top: 15px;
    height: 22px;
    width: 22px;
    transition: 0.5s;
    cursor: pointer;
    z-index: 5;
}

.btn-close:hover {
    transform: rotate(180deg);
}

.modal-body {
    margin-top: 20px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-body div {
    width: 100%;
    // max-width: 327px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        color: var(--font-color);
        font-size: 14px;
    }
}

.modal-body div:last-of-type {
    border: none;
}

.modal-body div:first-of-type {
    margin-top: 15px;
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .25s ease;
}

.modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.confirm-btn {
    width: 100%;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    height: 44px;
}

.sell-btn {
    background: #D72F70;
}

.buy-btn {
    background: #80CC52;
}

.normal-btn img {
    margin-left: 10px;
}
</style>
