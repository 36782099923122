<template>
<div class="application">
    <div class="introduce">
        <p class="title">
            اپلیکیشن صرافی
            <span class="name">نوتیبای</span>
        </p>
        <p class="introduce-p">
            خرید و فروش و معامله‌ی انواع ارزهای دیجیتال در هر زمان و هر مکان
        </p>
        <div class="links">
            <a class="link" :href="info.appAndroid" target="_blank">
                <div>
                    <img src="@/assets/android.png" alt="">
                </div>
                <div>
                    <p class="for">
                        دریافت مستقیم برای
                    </p>
                    <p class="platform">
                        اندروید
                    </p>
                </div>
            </a>
            <a class="link" :href="info.appIOS" target="_blank">
                <div>
                    <img src="@/assets/playstore.png" alt="">
                </div>
                <div>
                    <p class="for">
                        دریافت  از
                    </p>
                    <p class="platform">
                        اندروید
                    </p>
                </div>
            </a>
            <a class="link" :href="info.appIOS" target="_blank">
                <div>
                    <img src="@/assets/Apple.png" alt="">
                </div>
                <div>
                    <p class="for">
                        دریافت  از
                    </p>
                    <img src='@/assets/apple-p.png' alt="" >
                </div>
            </a>
            <a class="link" :href="info.appIOS" target="_blank">
                <div>
                    <img src="@/assets/anardooni.png" alt="">
                </div>
                <div>
                    <p class="for">
                        دریافت  از
                    </p>
                    <img src='@/assets/anardooni-p.png' alt="" >
                </div>
            </a>
        </div>
    </div>
    <img class="mob-img" src="@/assets/notimock.svg" alt="">
</div>
</template>

<script>
export default {
    name: 'Aplication',
    components: {

    },
    data() {
        return {
            info: {},
        }
    },
    methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.userscount = res.baseDTO.userCount
            this.tradprice = res.baseDTO.tradesValue
            this.totaltrad = res.baseDTO.tradesAmount
            const res1 = await this.$axios.get('/exchange-info')
            this.info = res1.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.application {
    display: flex;
    align-items: center;
    max-width: 1250px;
    flex-grow: 1;
    width: 100%;
    column-gap: 200px;
    padding: 0 20px;
    row-gap: 20px;
    flex-wrap: wrap;

    .introduce {

        .title,
        .name {
            font-weight: normal;
            font-size: 32px;
        }

        .title {
            color: black;
        }

        .name {
            color: #80CC52;
        }
    }

    .introduce-p {
        color: #404040;
        font-weight: normal;
        font-size: 22px;
        text-align: center;
    }

    .links {
        display: flex;
        row-gap: 10px;
        column-gap: 10px;
        max-width: 500px;
        flex-wrap: wrap;

        .link {
            background: #80CC52;
            border-radius: 3px;
            width: 175px;
            height: 50px;
            display: flex;
            column-gap: 20px;
            align-items: center;
            padding: 0 10px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background: #70B347;
            }

            .for {
                font-weight: normal;
                font-size: 10px;
                color: #FFFFFF;
                margin: 0;
            }

            .platform {
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                margin: 0;
            }
        }
    }

    .mob-img {
        width: 310px;
        height: 368px;
        max-width: 100%;
    }
}
@media only screen and (max-width:600px) {
    .links {
        justify-content: center;
    }
}
</style>
