<template>
<div class="fee">
    <p class="title">
        کارمزد ها
    </p>
    <div class="fee-container">
        <div>
            <p>
                کارمزد معاملات نوتیبای به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد:
            </p>
        </div>
        <div>
            <p>
                <span>
                    <img src="@/assets/Polygon.png" alt="">
                </span>
                کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به تومان کسر می شود.
            </p>
            <p>
                <span>
                    <img src="@/assets/Polygon.png" alt="">
                </span>
                کارمزد از هر دو سمت معامله کسر می شود.
            </p>
            <p>
                <span>
                    <img src="@/assets/Polygon.png" alt="">
                </span>
                در هنگام ثبت معاملات در والکس، کارمزد به شما نمایش داده می شود.
            </p>
        </div>
        <div>
            <p class="sub-title">
                سطوح کاربری و کارمزد معاملات
            </p>
        </div>
        <div>
            <p>
                با توجه به حجم معاملات سه ماه اخیر کاربر، کارمزد معاملات طبق جدول زیر به صورت پلکانی محاسبه می گردد:
            </p>
            <div class="table-container">
                <table>
                    <tr class="tr-head">
                        <td>
                            سطح کاربری
                        </td>
                        <td>
                            میزان معامله در ۳ ماه اخیر
                        </td>
                        <td>
                            کارمزد
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index">
                        <td>
                            سطح کاربری {{$levels[level.userAccountLevel]}}
                        </td>
                        <td class="number-font" v-if="index === 0">
                            کمتر از {{tradeAmount($accountLevel[index].tradeAmount)}} میلیون
                        </td>
                        <td class="number-font" v-else-if="index === ($accountLevel.length-2)">بیشتر از
                            {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                            میلیون
                        </td>
                        <td v-else>
                            از
                            {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                            تا
                            {{tradeAmount($accountLevel[index].tradeAmount)}}
                            میلیون
                        </td>
                        <td class="number-font">
                            % {{level.wagePercent}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <p class="sub-title">
                کارمزد برداشت ارزها
            </p>
        </div>
        <div>
            <p>
                کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و نوتیبای در آن نقشی ندارد. در حال حاضر این هزینه‌ها به شرح زیر است:
            </p>
        </div>
        <div>
            <div class="table-container">
                <table>
                    <tr class="tr-head">
                        <td>
                            نوع ارز
                        </td>
                        <td>
                            حداقل میزان قابل برداشت
                        </td>
                        <td>
                            کارمزد
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(coin , index) in coins" :key="index">
                        <td>
                            {{$coins[coin.coin].persianName}}
                        </td>
                        <td class="min-td">
                            <span class="number-font"> {{coin.tokenTypeDetails[0].minWithdraw}} </span>&nbsp;
                            <span> {{$coins[coin.coin].code}}</span>

                        </td>
                        <td class="fee-td">
                            <span> {{coin.tokenTypeDetails[0].withdrawalFee}} </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <p class="sub-title">
                کارمزد برداشت ریالی
            </p>
            <p>
                برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجامد.
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HomePage',
    components: {

    },
    data() {
        return {
            coins: []
        }
    },
    computed: {
        tradeAmount() {
            return e => e ? e.toString().slice(0, -6) : 0
        }
    },
    methods: {
        async getData() {
            const res = await this.$axios.get('/coins/details?p2pSupported=true')
            this.coins = res.content
        }
    },
    mounted() {
        this.$getFees()
        this.getData()
    }

}
</script>

<style scoped>
.fee {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    font-weight: bold;
    font-size: 36px;
}

.fee-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

table {
    width: 95%;
    margin: 0 auto;
    border-radius: 3px;
    /* background: rgba(61, 73, 204, 0.03); */
    border-collapse: collapse;
    /* text-align:center; */
    table-layout: fixed;
    min-width: 400px;
}

tr {
    height: 54px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}

tr td:first-of-type {
    padding-right: 20px;
    text-align: right;
}

tr td:last-of-type {
    text-align: left;
}

.tr-head {
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    border-bottom: 1px solid rgba(61, 73, 204, 0.3);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
}

.tr-body {
    color: #404040;
    border-bottom: 1px solid rgba(61, 73, 204, 0.1);
    text-align: center;
}

.table-container {
    width: 90%;
    margin: 0 auto;
    background: rgba(61, 73, 204, 0.03);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.min-td,
.fee-td {
    direction: ltr;
}

.sub-title {
    font-weight: normal;
    font-size: 22px;
    color: #404040;

}
</style>
