<template>
<div class="policy">
    <p class="title">
        قوانین و شرایط
    </p>
    <div class="policy-container">
        <div>
           <p>صرافی نوتیبای با تابعیت از قوانین جمهوری اسلامی ایران بستری برای تبادل دارایی‌های دیجیتال مانند بیت کوین، لایت کوین و اتریوم با ریال می‌باشد. تبادلات ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.</p> 

            <p>تعهدنامه فعالیت در بستر تبادل دارایی‌های دیجیتال صرافی نوتیبای</p> 

           <p>کاربران موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.</p> 

            کاربران سایت می‌پذیرند که کلیه‌ی فعالیت‌های آن‌ها در صرافی نوتیبای در چارچوب قوانین جمهوری اسلامی بوده و هیچ گونه فعالیتی خارج از این چارچوب انجام نخواهند داد.
            احراز هویت برای استفاده از خدمات صرافی نوتیبای ضروری بوده و کاربران موظفند اطلاعات صحیح خود را بارگذاری نمایند. بدیهی است در صورت وجود هرگونه تخلف در احراز هویت، مسئولیت به عهده‌ی فرد متخلف بوده و صرافی نوتیبای حق توقف ارائه خدمات به کاربر مذبور و ارجاع موارد تخلف به مراجع ذی صلاح را خواهد داشت. صرافی نوتیبای خود را ملزم به حفظ اطلاعات شخصی کاربران خود می‌داند.
            کاربران صرافی نوتیبای تعهد می دهند که از خدمات سایت صرافی نوتیبای تنها برای خود استفاده نموده و مسئولیت استفاده از خدمات صرافی نوتیبای برای فرد غیر که فرآیند احراز هویت را طی نکرده باشد به عهده کاربر خواهد بود. شماره حساب اعلام شده به سایت و همچنین آدرس کیف پول ها جهت برداشت رمزارز نیز می بایست متعلق به کاربر بوده و کاربران مجاز به دادن آدرس کیف پول متعلق به اشخاص دیگر نیستند.
            کاربران صرافی نوتیبای می‌پذیرند در صورت تشخیص کارشناسان پشتیانی صرافی نوتیبای، جهت حفظ امنیت دارایی حساب کاربریشان با اطلاع قبلی نسبت به برقراری تماس تصویری با تیم صرافی نوتیبای همکاری نمایند. همچنین بنا به دستور نهادهای قضایی مانند پلیس فتا، ممکن است برداشت رمزارز به مدت 24 تا 72 ساعت کاری پس از زمان واریز از طریق درگاه شتابی، محدود گردد.
            کاربران صرافی نوتیبای اذعان می‌دارند که از ریسک‌های مربوط به سرمایه‌گذاری در ارزهای دیجیتال مطلع بوده و با علم به این موضوع اقدام به سرمایه‌گذاری و تبادل می‌نمایند.
            صرافی نوتیبای به عنوان بازار آنلاین تبادل ارزهای دیجیتال، هیچ گونه مسئولیتی در قبال نحوه‌ی معاملات کاربران و سود و زیان حاصل از آن ندارد.
            هرگونه سهل انگاری کاربران در حفظ اطلاعات امنیتی از جمله گذرواژه کاربر، به عهده‌ی شخص کاربر بوده و صرافی نوتیبای هیچ مسئولیتی به عهده نخواهد داشت. اکیداً توصیه می شود از گذرواژه پیچیده و امن و کد شناسایی دوعاملی استفاده شود.
            صرافی نوتیبای این اطمینان را می‌دهد که دارایی‌های کاربران را نزد خود به امانت و به بهترین شکل و با بالاترین استانداردهای امنیتی ممکن، حفظ نماید. در صورت بروز هرگونه مشکل امنیتی، صرافی نوتیبای متعهد به جبران خسارت خواهد بود.
            در صورت تمایل برای برداشت ارزهای دیجیتال، مسئولیت ارائه‌ی آدرس صحیح کیف پول به عهده‌ی کاربر خواهد بود. در صورت بروز هر گونه مشکل اعم از اشتباه در ورود آدرس صحیح، نقص آدرس، مشکلات کیف پول مقصد و بلوکه شدن دارایی‌های کاربران در کیف پول مقصد، هیچ گونه مسئولیتی به عهده ی صرافی نوتیبای نخواهد بود.
            صرافی نوتیبای در مقابل واریز توکن یا کوین بر بستر اشتباه یا کوین هایی که در صرافی نوتیبای پشتیبانی نمی شود هیچ گونه مسئولیتی نداشته و با توجه به مکانیسم ذخیره سازی سرد امکان استخراج این توکن ها با استفاده از ولت ثالث وجود ندارد. لذا مسئولیت هرگونه واریز اشتباه با خود کاربر بوده و کاربر حق هیچ گونه شکایتی را نخواهد داشت.
            درخواست برداشت ریال از حساب کاربری در سریع‌ترین زمان ممکن پس از ثبت، بررسی خواهد شد. زمان واریز پول به حساب کاربران بر اساس محدودیت‌های انتقال وجه بین بانکی، متفاوت خواهد بود. برای اطلاعات بیش‌تر، به قوانین انتقال وجه بین بانکی ( پایا ، ساتنا) مراجعه فرمایید.
            کاربر می‌پذیرد که به جز در موارد مورد تعهد صرافی نوتیبای ، حق هیچ گونه داعیه، طلب و شکایت از سایت صرافی نوتیبای ، مدیران، کارمندان و افراد مرتبط با این سایت را نخواهد داشت.
            اگر صرافی نوتیبای تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا رمزارزی را به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات اداری و قضائی و دریافت اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص صرافی نوتیبای نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد صرافی نوتیبای از هر جهت از خود ساقط می نماید.
            در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، صرافی نوتیبای حق دارد مستقلاً آن معامله را ابطال و دارایی‌های هر یک از طرفین را به حساب خودشان عودت دهد. بدیهی است که در صورتی که اشکال از سمت صرافی نوتیبای باشد، موظف خواهد بود که جبران خسارت نماید و در غیراین صورت کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد صرافی نوتیبای از هر جهت از خود ساقط می‌نماید.
            در صورت هرگونه سوء استفاده از کد ریفرال صرافی نوتیبای در فعالیت‌های خلاف قانون، تمامی مسئولیت آن به عهده کاربر بوده و صرافی نوتیبای هیچ گونه مسئولیتی در قبال هرگونه سوء استفاده از سیستم معرفی خود به هر نحو ندارد. همچنین صرافی نوتیبای حق باطل کردن کد معرف و بستن حساب در صورت مشاهده هرگونه سوء استفاده بدون نیاز به دستور قضایی را خواهد داشت.
            در صورت فوت یا حجر کاربر، صرافی نوتیبای این حق را خواهد داشت به محض اطلاع از آن نسبت به واریز وجوه ریالی کاربر نزد شرکت، به حساب اعلام شده ایشان در زمان ثبت نام اقدام نموده و در خصوص دارائی رمز ارز نیز این حق و اختیار به شرکت واگذار گردید که نسبت به تبدیل آن به ریال معادل قیمت روز و واریز آن به حساب فوق اقدام نماید و این حق و امتیاز توسط کاربر به شرکت صلح و اعطا گردید. لازم به ذکر است ملاک زمانی محاسبه قیمت فروش دارائی رمز ارز کاربر، براساس ارائه اسناد مثبته توسط ذینفع و تایید اصالت آن توسط واحد حقوقی صرافی نوتیبای است. کاربر با علم و اطلاع از مقررات این بند نسبت به افتتاح حساب کاربری در صرافی نوتیبای اقدام نموده و کاربر، اشخاص ثالث، ورثه یا قائم مقام قانونی ایشان حق اعتراض و یا ادعایی در آینده در این خصوص را نخواهند داشت.
        </div>
    </div>
</div>
</template>

<script>
import question from "../../library/Questions.js"
export default {
    name: 'HomePage',
    components: {

    },
    data() {
        return {
            questions: question,
            privacyPolicy: '',
        }
    },
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info?language=PERSIAN')
            this.privacyPolicy = res.baseDTO
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style scoped>
.policy {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    font-weight: bold;
    font-size: 36px;
}

.policy-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
</style>
